import { Button, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";

import {
  CREATE_PHONE_NUMBER_ERROR_DIALOG,
  DIALOG_VARIANTS
} from "../../../components/common/Dialogs/DialogConsts";
import { deploymentService } from "../../../services";

const CreatePhoneNumberButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: "white",
  border: `1px solid ${
    disabled ? theme.palette.text.disabled : theme.palette.secondary.main
  }`,
  color: theme.palette.secondary.main,
  padding: "5px 12px",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "16px"
}));

export const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== "cursor"
})(({ variant, cursor = "auto" }) => ({
  border: variant === "outlined" ? "1px solid #4548C4" : "none",
  cursor,
  borderRadius: "16px"
}));

const StatusMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled
}));

const CreatePhoneNumber = ({ currentPhoneNumber, onGenerateNumber, canCreateNumbers }) => {
  const [connectingNumber, setConnectingNumber] = useState(false);
  const [error, setError] = useState(null);

  const generatePhoneNumber = async (e) => {
    // need this to stop table row opening on click
    e.stopPropagation();

    setConnectingNumber(true);

    try {
      const newPhoneNumber = await deploymentService.addNewNumber({});
      onGenerateNumber(newPhoneNumber.replace("+", ""));
    }
    catch (error) {
      setError(true);
    }
    setConnectingNumber(false);
  };
  return currentPhoneNumber
    ? (
      <>
        {Array.isArray(currentPhoneNumber)
          ? (
            <>
              <StyledChip cursor="pointer" label={currentPhoneNumber?.[0]} />
              <StyledChip
                cursor="pointer"
                sx={{ ml: "14px" }}
                label={`+${currentPhoneNumber?.length - 1}`}
                variant="outlined"
              />
            </>
          )
          : (
            currentPhoneNumber
          )}
      </>
    )
    : error
      ? (
        <Dialog
          open={Boolean(error)}
          title={CREATE_PHONE_NUMBER_ERROR_DIALOG.title}
          submitButtonText={CREATE_PHONE_NUMBER_ERROR_DIALOG.buttonText}
          handleSubmit={(e) => {
            e.stopPropagation();
            setError(null);
          }}
          variant={DIALOG_VARIANTS.acknowledgement}
        >
          <Typography>{CREATE_PHONE_NUMBER_ERROR_DIALOG.content}</Typography>
        </Dialog>
      )
      : connectingNumber
        ? (
          <StatusMessage variant="caption">Connecting...</StatusMessage>
        )
        : (
          <CreatePhoneNumberButton
            onClick={generatePhoneNumber}
            disabled={!canCreateNumbers}
          >
                        + Create Number
          </CreatePhoneNumberButton>
        );
};

export default CreatePhoneNumber;
