import { Stack, styled, chipClasses, Button, inputBaseClasses, formControlClasses, Box } from "@mui/material";
import { gridClasses } from "@mui/x-data-grid";

export const StyledContainer = styled(Stack, {
  shouldForwardProp: (props) => !["stickyActions", "clickable", "minHeightAuto", "disableHover"].includes(props)
})(({ stickyActions, clickable, minHeightAuto, disableHover }) =>
  ({
    [`& .${gridClasses.root}`]: {
      "--DataGrid-containerBackground": "#FAFAFA",
      minHeight: `${minHeightAuto ? "auto" : "457px"}`,
      [`& .${gridClasses.columnHeader}`]: {
        '&[aria-colindex="1"]': { paddingLeft: "24px" },
        cursor: "default",
        "&:focus": { outline: "none" },
        '&[data-field="actions"]': {
          position: "sticky",
          right: -1,
          backgroundColor: "#FAFAFA"
        }
      },
      [`& .${gridClasses.columnHeaderTitle}`]: {
        fontWeight: "600"
      },
      [`& .${gridClasses.cell}`]: {
        padding: "6px 10px 6px 0px",
        minHeight: "56px",
        display: "flex",
        alignItems: "center",
        overflow: "visible",
        '&[aria-colindex="1"]': { paddingLeft: "14px !important" },
        "&:focus, &:focus-within": { outline: "none" },
        "&.error-cell .cell-container": { outline: "1px solid #EF9AA1" },
        '&[data-field="actions"]': {
          position: "sticky",
          right: -1,
          zIndex: 1,
          display: "grid",
          placeContent: "center",
          backgroundColor: "#fff",
          boxShadow: stickyActions ? "-2px 0px 2px 2px rgba(102, 102, 102, 0.01), -4px 0px 8px 0px rgba(85, 85, 85, 0.05)" : "none" }
      },
      [`& .${gridClasses.row}`]: {
        backgroundColor: "#fff",
        "&:hover": { backgroundColor: "#fff" },
        [`&:hover:not(:has(.${gridClasses["cell--editing"]}))`]: {
          backgroundColor: disableHover ? "unset" : "#f7f7f8",
          '[data-field="actions"]': {
            backgroundColor: disableHover ? "unset" : "#f7f7f8"
          } },
        ...(clickable && {
          cursor: "pointer",
          [`& .${chipClasses.root}`]: { cursor: "pointer" }
        })
      },
      [`& .${gridClasses.columnSeparator}`]: {
        [`&:not(.${gridClasses["columnSeparator--resizable"]})`]: {
          display: "none"
        }
      },
      [`& .${gridClasses.cell} .${inputBaseClasses.root}`]: {
        zIndex: 1,
        borderRadius: "8px",
        padding: "10px 0",
        width: "100%",
        "&:focus": { outline: "none" },
        "& fieldset": { border: "none", outline: "none" }
      },
      [`& .${gridClasses.cell} .${inputBaseClasses.input}`]: {
        padding: "0 10px",
        width: "100%"
      },
      [`& .${gridClasses["cell--editable"]}`]: {
        position: "relative",
        cursor: "pointer",
        [`& .${chipClasses.root}`]: { cursor: "pointer" },
        "&:hover .list-arrow-down": { display: "block" }
      },
      [`& .${formControlClasses.root}`]: {
        width: "100%"
      },
      [`& .${gridClasses.cell}.${gridClasses["cell--editing"]}`]: {
        padding: "6px 10px 6px 0px",
        boxShadow: "none",
        "&:focus-within": { outline: "none !important" },
        [`& .${inputBaseClasses.root}.${inputBaseClasses.focused}:not(.${inputBaseClasses.error})`]: {
          outline: "2px solid #4548C4", outlineOffset: "-2px"
        },
        [`& .${inputBaseClasses.root}.${inputBaseClasses.error}`]: {
          outline: "1px solid #EF9AA1", outlineOffset: "-1px"
        }
      },
      [`& .${gridClasses["cell--editing"]}`]: { boxShadow: "none" }
    }
  }));

export const TextWrapper = styled("div")(({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  WebkitLineClamp: 3,
  textOverflow: "ellipsis"
}));

export const AddNewRowButton = styled(Button)({
  height: "59px",
  width: "var(--DataGrid-width)",
  justifyContent: "flex-start",
  padding: "12px 24px",
  borderRadius: 0,
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  position: "sticky",
  left: 0,
  "&:hover": { backgroundColor: "#f7f7f8" }
});

export const StyledCellContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isEditable"
})(({ isEditable }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "44px",
  width: "100%",
  padding: "4px 10px",
  borderRadius: "8px",
  lineHeight: "24px",
  outlineOffset: "-1px",
  "&:hover": {
    outline: isEditable ? "1px solid #2D2C443D" : "none"
  }
}));
