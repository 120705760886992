import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { not } from "ramda";
import React from "react";

import { useCurrentAdminPage } from "../../../hooks";
import Navbar from "../Navbar";

const MobileAdminNavbar = ({ adminNavItems, open, setOpen }) => (<>
  <IconButton
    color="inherit"
    aria-label="open drawer"
    onClick={() => setOpen(not)}
  >
    <MenuIcon />
  </IconButton>
  <Navbar
    coreNavItems={adminNavItems}
    useCurrentPage={useCurrentAdminPage}
    open={open}
    setOpen={setOpen}
  />
</>);

export default MobileAdminNavbar;
