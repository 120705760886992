import { Portal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import useSnackbar from "src/components/common/useSnackbar";
import { INITIATE_CALL_WENT_WRONG, RECEIVE_CALL_SHORTLY } from "src/phrasing";
import { voiceService } from "src/services";
import { isAssistantPreviewableSelector, pageNameSelector } from "src/stores/selectors/appSelectors";
import { isUpdatingCollectionSelector } from "src/stores/selectors/collectionsSelectors";
import { buildStatusPollingInProgressSelector } from "src/stores/selectors/previewSelectors";
import { SYSTEM_MODES } from "src/stores/slices/createAppSlice";
import { SEVERITY_VARIANTS, BUILD_STATUSES } from "src/utils";

import CallMeDialog from "./CallMeDialog";
import HyroFeedbackMessage from "../../../components/hyro-components/HyroFeedbackMessage";
import PhoneRingingIcon from "../../../icons/PhoneRingingIcon";
import useAppStore from "../../../stores/appStore";
import { StyledButton, StyledIconButton } from "../HeaderStyles";

// eslint-disable-next-line complexity
const CallMeManager = ({ isButton }) => {
  const systemMode = useAppStore(state => state.systemMode);
  const draft = useAppStore(state => state.draft);
  const [callRequestSent, setCallRequestSent] = useState(false);
  const pageName = useAppStore(pageNameSelector);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const isAssistantPreviewable = useAppStore(isAssistantPreviewableSelector);
  const buildStatusPollingInProgress = useAppStore(buildStatusPollingInProgressSelector);
  const isUpdatingCollection = useAppStore(isUpdatingCollectionSelector);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [
    isSnackbarOpen,
    snackbarMessage,
    addSnackbarMessage,
    exitSnackbar,
    closeSnackbar
  ] = useSnackbar();
  const buildDraftResponse = useAppStore(state => state.buildDraftResponse);

  const handleCallMe = async ({ phoneNumber, callerId, site }) => {
    try {
      setCallRequestSent(true);
      const data = {
        phoneNumber,
        callerId,
        site,
        ...(systemMode === SYSTEM_MODES.EDIT ? { branchName: draft?.branchName } : {})
      };
      await voiceService.initiateCall(data, { pageName });
      setCallRequestSent(false);
      setPhoneDialogOpen(false);
      setSnackbarSeverity(SEVERITY_VARIANTS.SUCCESS);
      addSnackbarMessage(RECEIVE_CALL_SHORTLY);
    } catch (e) {
      setCallRequestSent(false);
      setSnackbarSeverity(SEVERITY_VARIANTS.ERROR);
      addSnackbarMessage(INITIATE_CALL_WENT_WRONG);
    }
  };

  const isPreviewDisabled =
       (draft && systemMode === SYSTEM_MODES.EDIT && buildDraftResponse?.status !== BUILD_STATUSES.LIVE)
    || (!draft && systemMode === SYSTEM_MODES.EDIT)
    || buildStatusPollingInProgress
    || !isAssistantPreviewable
    || isUpdatingCollection;

  return <>
    {isButton ? <StyledButton
      disabled={isPreviewDisabled}
      variant="outlined"
      startIcon={<PhoneRingingIcon size={20} disabled={isPreviewDisabled} />}
      onClick={() => {
        setPhoneDialogOpen(true);
        setCallRequestSent(false);
      }}
    >
      Preview
    </StyledButton> : <Tooltip title={"Conversation preview"}>
      <span>
        <StyledIconButton
          aria-label="call"
          disabled={isPreviewDisabled}
          onClick={() => setPhoneDialogOpen(true)}
        >
          <PhoneRingingIcon disabled={isPreviewDisabled} />
        </StyledIconButton>
      </span>
    </Tooltip>}
    {phoneDialogOpen ? <CallMeDialog
      callRequestSent={callRequestSent}
      isOpen={phoneDialogOpen}
      onClose={() => setPhoneDialogOpen(false)}
      handleCallMe={handleCallMe}
    /> : null}
    <Portal>
      <HyroFeedbackMessage
        message={snackbarMessage}
        severity={snackbarSeverity}
        showFeedback={isSnackbarOpen}
        onExited={exitSnackbar}
        closeFeedback={closeSnackbar}
      />
    </Portal>
  </>;
};

export default CallMeManager;
