import { Chip } from "@hyro/ui";
import { Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React from "react";
import Jdenticon from "react-jdenticon";

import { ASSISTANT_TYPE_TO_ICON } from "../../../utils";

const AssistantName = styled(props => <Typography variant="h6" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.text.primary
  })
);

const AssistantDetails = styled(props => (
  <Typography variant="caption" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.secondary
}));

const ChipContainer = styled("div")(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "flex-end"
}));

const CardContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "showSkeleton"
})(({ theme, showSkeleton }) => ({
  "padding": theme.spacing(2),
  "height": "194px",
  "display": "flex",
  "flexDirection": "column",
  "border": "1px solid #2D2C441F",
  "borderRadius": theme.shape.borderRadius,
  "boxShadow": theme.shadows[1],
  "transition": "0.1s",
  "&:hover": showSkeleton ? {} : {
    marginTop: "-4px",
    marginBottom: "4px",
    cursor: "pointer",
    boxShadow: theme.shadows[4]
  }
}));

const CardHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row"
}));

const CardFooter = styled("div")(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end"
}));

const AssistantCard = (
  {
    assistantId,
    assistantName,
    date,
    dateType,
    channel,
    assistantType,
    onClick,
    showSkeleton
  }
) => (<CardContainer onClick={onClick} showSkeleton={showSkeleton} data-testid={assistantId}>
  <CardHeader>
    {showSkeleton
      ? (
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: "8px" }}
          width={40}
          height={40}
        />
      )
      : (
        <Jdenticon value={assistantId} size="40" />
      )}
    <ChipContainer>
      {showSkeleton
        ? (
          <Skeleton
            variant="rounded"
            sx={{ borderRadius: "500px" }}
            width={60}
            height={30}
          />
        )
        : (
          <Chip
            size="small"
            label={assistantType}
            icon={ASSISTANT_TYPE_TO_ICON[assistantType]
                          && (
                            <img
                              src={ASSISTANT_TYPE_TO_ICON[assistantType]?.src}
                              alt={assistantType}
                            />
                          )}
            textColorPath={ASSISTANT_TYPE_TO_ICON[assistantType]?.textColorPath}
            backgroundColorPath={
              ASSISTANT_TYPE_TO_ICON[assistantType]?.backgroundColorPath
            }
          />
        )}
    </ChipContainer>
  </CardHeader>
  <CardFooter>
    {showSkeleton
      ? (
        <Skeleton variant="text" sx={{ fontSize: "20px", width: "120px" }} />
      )
      : (
        <AssistantName data-testid={assistantName}>{assistantName}</AssistantName>
      )}
    {showSkeleton
      ? (
        <Skeleton variant="text" sx={{ fontSize: "12px", width: "150px" }} />
      )
      : (
        <AssistantDetails>
          {`${channel} `}
          {date && `• ${dateType} ${moment(date).format("D MMM, YYYY")}`}
        </AssistantDetails>
      )}
  </CardFooter>
</CardContainer>);

export default AssistantCard;
