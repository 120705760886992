export const PHONE_NUMBER_ERROR
  = "Invalid phone number. Number should contain digits only, either 11 or 13 characters, including the plus sign (+).";

export const RECEIVE_CALL_SHORTLY = "You should receive a call shortly.";

export const INITIATE_CALL_WENT_WRONG
  = "Something went wrong while initiating the call.";

export const CALL_ME_FIELDS = {
  PHONE_NUMBER: {
    ID: "phoneNumber",
    LABEL: "Phone number",
    PLACEHOLDER: "Enter US or IL phone number",
    ERROR: "Invalid format. Please enter a valid US or IL number containing digits only, either 10, 11 or 13 characters long, including the plus sign (+).",
    TOOLTIP_TITLE: "Recipient's Phone Number",
    TOOLTIP_DESCRIPTION: "Enter the phone number of the recipient you want to call for this preview. Ensure the number is correctly formatted.\n"
  },
  CALLER_ID: {
    ID: "callerId",
    LABEL: "Caller ID",
    PLACEHOLDER: "Enter caller id",
    ERROR: "Invalid format. Please enter a valid US number containing digits only, either 10 or 11 characters long, including the plus sign (+).",
    TOOLTIP_TITLE: "Caller ID",
    TOOLTIP_DESCRIPTION: "Enter the phone number you want to be identified as during this preview call."
  },
  SITES: {
    ID: "site",
    LABEL: "Site",
    PLACEHOLDER: "Select a site",
    ERROR: "",
    TOOLTIP_TITLE:"Site",
    TOOLTIP_DESCRIPTION: "Choose the site for the scheduled call. This is typically the clinic or location with which the call will be associated."
  }
};
