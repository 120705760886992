import { Button, buttonClasses, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  handleDraftTripletsSelector,
  isFirstDraftSelector,
  isPublishingTripletsSelector
} from "src/stores/selectors/tripletsSelectors";
import useAppStore from "../../../../../stores/appStore";
import DiscardIcon from "../../../../../icons/DiscardIcon";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";
import { COLORS, DISCARD_DRAFT_DIALOG } from "src/layout/consts";
import { DIALOG_VARIANTS } from "src/components/common/Dialogs/DialogConsts";
import {
  isDiscardingChangesSelector,
  updateConfigAndTripletsSelector
} from "src/stores/selectors/appSelectors";
import { useQueryClient } from "@tanstack/react-query";
import { FETCH_QUERY_KEYS } from "src/queries/consts";
import { removeInactiveQueries } from "src/queries/utils";

const DraftButtons = ({ inProgressOrTestIsRunning, setSnackbarAlert }) => {
  const isPublishingTriplets = useAppStore(isPublishingTripletsSelector);
  const updateConfigAndTriplets = useAppStore(updateConfigAndTripletsSelector);
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const isDiscardingChanges = useAppStore(isDiscardingChangesSelector);
  const [, deleteCurrentDraft] = useAppStore(handleDraftTripletsSelector);
  const queryClient = useQueryClient();
  const [deleteDraftConfirmationOpen, setDeleteDraftConfirmationOpen] = useState(false);

  const removeCollectionsCache = () => {
    // Remove all inactive collection-related queries to avoid stale data
    removeInactiveQueries({ queryKey: [FETCH_QUERY_KEYS.COLLECTIONS], queryClient });
    removeInactiveQueries({ queryKey: [FETCH_QUERY_KEYS.COLLECTION], queryClient });
    removeInactiveQueries({ queryKey: [FETCH_QUERY_KEYS.COLLECTION_REFS], queryClient });

    // Invalidate active queries to refetch in the background
    queryClient.invalidateQueries({ queryKey: [FETCH_QUERY_KEYS.COLLECTIONS] });
    queryClient.invalidateQueries({ queryKey: [FETCH_QUERY_KEYS.COLLECTION] });
    queryClient.invalidateQueries({ queryKey: [FETCH_QUERY_KEYS.COLLECTION_REFS] });
  };

  const handleDiscard = async () => {
    const res = await deleteCurrentDraft();

    removeCollectionsCache();

    if (res?.error) {
      setSnackbarAlert({
        open: true,
        message: "Action failed. Please contact support.",
        severity: "error"
      });
    } else {
      updateConfigAndTriplets();
    }
  };

  return (
    <Stack direction="row" gap={1} alignItems={"center"}>
      {!isFirstDraft ? <Button
        sx={{
          height: 22,
          p: "5px 8px",
          lineHeight: "14px",
          borderRadius: "4px",
          fontSize: "13px",
          [`.${buttonClasses.startIcon}`]: { ml: 0, mr: .5 },
          [`&.${buttonClasses.disabled}`]: { bgcolor: COLORS.DISABLED_BTN_BG, color: COLORS.DISABLED_BTN_COLOR }
        }}
        startIcon={<DiscardIcon disabled={isPublishingTriplets || inProgressOrTestIsRunning} />}
        variant='contained'
        disabled={isPublishingTriplets || inProgressOrTestIsRunning}
        onClick={() => {
          setDeleteDraftConfirmationOpen(true);
        }}
      >
        Discard draft
      </Button> : null}
      <Dialog
        handleClose={() => setDeleteDraftConfirmationOpen(false)}
        handleCancel={() => setDeleteDraftConfirmationOpen(false)}
        handleSubmit={handleDiscard}
        open={deleteDraftConfirmationOpen}
        showCloseButton
        cancelButtonText={DISCARD_DRAFT_DIALOG.CANCEL_BUTTON_TEXT}
        submitButtonText={DISCARD_DRAFT_DIALOG.SUBMIT_BUTTON_TEXT}
        submitButtonLoading={isDiscardingChanges}
        title={DISCARD_DRAFT_DIALOG.TITLE}
        variant={DIALOG_VARIANTS.transactional}
      >
        <Typography>{DISCARD_DRAFT_DIALOG.TEXT}</Typography>
      </Dialog>
    </Stack>
  );
};

export default DraftButtons;
