import { del, get as apiGet, get as Get, patch, post } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { collectionNames } from "../../components/collections/consts";
import { SYSTEM_MODES } from "./createAppSlice";
import { retryAPICall } from "src/stores/slices/utils";
import { datadogRum } from "@datadog/browser-rum";
import { CUSTOM_ACTIONS } from "src/analytics/notifyCustomActions";

// eslint-disable-next-line max-lines-per-function
const createCollectionsSlice = (set, get) => ({
  isPageLoading: true,
  isCollectionLoading: true,
  hasDialogError: false,
  isDialogLoading: false,
  collections: null,
  collectionsLanguages: [],
  defaultCollectionsLanguages: null,
  // assistant config
  currentCollectionAssistantConfig: {},
  collectionAssistantConfig: {},
  collectionOriginalAssistantConfig: {},
  // collection config
  collectionConfig: {},
  originalCollectionConfig: {},
  currentCollectionConfig: {},
  collectionSpec: null,
  selectedCollection: {},
  isUpdatingCollection: false,
  collectionConfigChanged: false,
  isSavingChanges: false,
  collectionName: "",
  validationResults: {},
  failedToUpdateDraft: false,
  lastCollectionCreatedKey: null,
  collectionBreadCrumbs: [],
  ignoreUrlChange: false,
  connectionResults: {},
  setIgnoreUrlChange: value => set({ ignoreUrlChange: value }),
  setLastCollectionCreatedKey: value => set({ lastCollectionCreatedKey: value }),
  addToCollectionBreadCrumbs: (value) => {
    const { collectionBreadCrumbs } = get();
    set({ collectionBreadCrumbs: [...collectionBreadCrumbs, value] });
    set({ ignoreUrlChange: true });
  },
  removeLastItemFromCollectionBreadCrumbs: () => {
    const { collectionBreadCrumbs } = get();
    set({ collectionBreadCrumbs: collectionBreadCrumbs.slice(0, -1) });
  },
  clearCollectionBreadCrumbs: () => {
    set({ collectionBreadCrumbs: [] });
  },
  setCollectionName: value => set({ collectionName: value }),
  setIsUpdatingCollection: value => set({ isUpdatingCollection: value }),
  setCollectionConfigChanged: value => set({ collectionConfigChanged: value }),
  setDialogLoading: value => set({ isDialogLoading: value }),
  setDialogError: value => set({ hasDialogError: value }),
  setPageLoading: value => set({ isPageLoading: value }),
  setCollectionLoading: value => set({ isCollectionLoading: value }),
  setPageError: value => set({ hasPageError: value }),
  setCollections: collections => {
    const { systemMode } = get();

    const configRef = Object.keys(collections?.draftAssistantConfig || {}).length > 0 ? collections.draftConfigRef : collections.configRef;

    const resultAssistantConfig = Object.keys(collections?.draftAssistantConfig || {}).length ?
      collections.draftAssistantConfig
      : collections.assistantConfig;

    set({
      isPageLoading: false,
      collections: {
        specRef: collections.specRef,
        configRef,
        originalConfig: collections.configRef,
        currentConfig: systemMode === SYSTEM_MODES.EDIT ? configRef : collections.configRef
      },
      collectionsLanguages: resultAssistantConfig.languages?.map(lang => lang.language),
      defaultCollectionsLanguages: resultAssistantConfig.languages?.find(lang => lang.is_default)?.language,
      collectionAssistantConfig: resultAssistantConfig,
      collectionOriginalAssistantConfig: collections.assistantConfig,
      currentCollectionAssistantConfig: systemMode === SYSTEM_MODES.EDIT ? resultAssistantConfig : collections.assistantConfig
    });
  },
  // eslint-disable-next-line complexity
  setCollection: (collection) => {
    const { systemMode, draft } = get();
    const { assistantConfig, draftAssistantConfig, draftConfig, config, spec, validationResults } = collection;
    const collectionConfig = Object.keys(draftAssistantConfig || {}).length ? draftConfig : config;
    const resultAssistantConfig = Object.keys(draftAssistantConfig || {}).length ?
      draftAssistantConfig
      : assistantConfig;

    set({
      collectionAssistantConfig: resultAssistantConfig,
      collectionOriginalAssistantConfig: assistantConfig,
      currentCollectionAssistantConfig: systemMode === SYSTEM_MODES.EDIT ? resultAssistantConfig : assistantConfig,
      collectionsLanguages: resultAssistantConfig.languages?.map(lang => lang.language),
      defaultCollectionsLanguages: resultAssistantConfig.languages?.find(lang => lang.is_default)?.language,
      collectionConfig,
      currentCollectionConfig: systemMode === SYSTEM_MODES.EDIT ? collectionConfig : config,
      originalCollectionConfig: config,
      collectionSpec: spec,
      selectedCollection: { ...(systemMode === SYSTEM_MODES.EDIT ? collectionConfig : config), spec },
      isCollectionLoading: false,
      isPageLoading: false,
      validationResults: {
        live: {},
        draft: validationResults,
        current: systemMode === SYSTEM_MODES.EDIT && draft ? validationResults : {}
      }
    });
  },
  getValidationResults: async () => {
    const { selectedAssistantId, systemMode, draft } = get();

    const getValidationResultsWithRetry = async () => {
      const getValidationResults = async () => {
        const validationResults = await Get(`/assistants/collections/${selectedAssistantId}/all/validations`);
        set({
          validationResults: {
            live: {},
            draft: validationResults,
            current: systemMode === SYSTEM_MODES.EDIT && draft ? validationResults : {}
          },
          couldNotSetUpAssistant: false
        });
      };

      const onError = (error) => {
        console.error("Failed to load validations", error);
        set({ couldNotSetUpAssistant: true });
        return { error: true };
      };

      return await retryAPICall(getValidationResults, 3, onError);
    };

    return await getValidationResultsWithRetry();
  },
  getCollectionOptions: async (collectionName) => {
    const { selectedAssistantId } = get();

    try {
      return await Get(`/assistants/collections/${selectedAssistantId}/${collectionName}/spec-refs`);
    } catch (e) {
      console.error("Failed to load collection options", e);
      return { error: true };
    }
  },
  resetCollectionsData: () => {
    set({
      collections: {},
      collectionAssistantConfig: {},
      collectionOriginalAssistantConfig: {},
      currentCollectionAssistantConfig: {},
      collectionsLanguages: [],
      defaultCollectionsLanguages: null,
      previewAssistantId: null,
      buildDraftResponse: { status: "", url: "" }
    });
  },
  createCollection: async ({ specKey, displayKey, description, collectionName, createFromRef }) => {
    const {
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      updateBuildingBlockSliceState,
      pageName
    } = get();
    set({ isDialogLoading: true });
    const lastCollectionCreatedKey = uuidv4();

    try {
      const {
        draftAssistantConfig,
        specRef,
        configRef,
        draftConfigRef,
        draft,
        validationResults
      } = await post(`/assistants/collections/${selectedAssistantId}/${collectionName}`, {
        assistant_configuration: collectionAssistantConfig,
        display_key: displayKey,
        description,
        key: lastCollectionCreatedKey,
        spec_key: specKey,
        userData: { name: userData.name, email: userData.email }
      }, null, { pageName });
      updateBuildingBlockSliceState({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft });
      set({
        validationResults: {
          live: {},
          draft: validationResults,
          current: validationResults
        }
      });

      if (createFromRef) {
        set({ lastCollectionCreatedKey });
      }
      return true;
    } catch (err) {
      console.error("Error occurred while creating collection", err);
      set({ hasDialogError: true });
      return false;
    }
  },
  deleteCollection: async (collection) => {
    const {
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      updateBuildingBlockSliceState,
      collectionName,
      pageName
    } = get();
    set({ isDialogLoading: true });

    try {
      const {
        draftAssistantConfig,
        specRef,
        configRef,
        draftConfigRef,
        draft,
        validationResults
      } = await del(`/assistants/collections/${selectedAssistantId}/${collectionName}`, {
        assistant_configuration: collectionAssistantConfig,
        userData: { name: userData.name, email: userData.email },
        ...collection
      }, { pageName });
      set({
        validationResults: {
          live: {},
          draft: validationResults,
          current: validationResults
        }
      });
      updateBuildingBlockSliceState({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft });
      return true;
    } catch (err) {
      console.error("Error occurred while deleting collection", err);
      set({ hasDialogError: true });
      return false;
    }
  },
  editCollection: async (collection) => {
    const {
      pageName,
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      updateBuildingBlockSliceState,
      collectionName
    } = get();
    set({ isDialogLoading: true });

    try {
      const {
        draftAssistantConfig,
        specRef,
        configRef,
        draftConfigRef,
        draft,
        validationResults
      } = await patch(`/assistants/collections/${selectedAssistantId}/${collectionName}`, {
        assistant_configuration: collectionAssistantConfig,
        userData: { name: userData.name, email: userData.email },
        ...collection
      }, { pageName });
      set({
        validationResults: {
          live: {},
          draft: validationResults,
          current: validationResults
        }
      });
      updateBuildingBlockSliceState({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft });
      return true;
    } catch (err) {
      console.error("Error occurred while editing collection", err);
      set({ hasDialogError: true });
      return false;
    }
  },
  updateCollection: async (collection, updateCollectionMutation) => {
    const {
      discardDraftIfNoChanges,
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      setSelectedCollectionConfig,
      collectionName,
      pageName,
      infoPopovers
    } = get();
    const { configuration: { key: collectionKey }, key: collectionInstanceKey } = collection;
    set({
      isSavingChanges: true,
      isUpdatingCollection: true
    });

    try {
      const params = {
        selectedAssistantId,
        collectionName,
        collectionKey,
        collectionInstanceKey,
        pageName,
        payload: {
          assistant_configuration: collectionAssistantConfig,
          instance_config: collection,
          userData: { name: userData.name, email: userData.email }
        }
      };

      const {
        assistantConfig,
        collectionConfig,
        draft,
        validationResults,
        spec
      } = await updateCollectionMutation.mutateAsync(params);

      const branchDeployerResponse = await apiGet(`/assistants/${selectedAssistantId}/build/status?branchName=${draft?.branchName}`);
      set({ buildDraftResponse: branchDeployerResponse });

      setSelectedCollectionConfig(collectionConfig, spec);
      set({ currentCollectionAssistantConfig: assistantConfig, draft });
      await discardDraftIfNoChanges();

      const updObj = {
        collectionConfig,
        currentCollectionConfig: collectionConfig,
        isSavingChanges: false,
        isUpdatingCollection: false,
        collectionAssistantConfig: assistantConfig,
        infoPopovers: { ...infoPopovers, draftExpiration: true },
        validationResults: {
          live: {},
          draft: validationResults,
          current: validationResults
        },
        buildDraftResponse: { status: "", url: "" }
      };

      if (spec) {
        updObj.collectionSpec = spec;
      }

      set(updObj);

      return true;
    } catch (err) {
      set({
        hasDialogError: true,
        isSavingChanges: false,
        isUpdatingCollection: false
      });
      console.error("Error occurred while configure collection", err);

      return false;
    }
  },
  setSelectedCollection: value => set({ selectedCollection: value }),
  setCollectionLoader: value => set({ setCollectionLoader: value }),
  setSelectedCollectionConfig: (config, spec) => {
    const { selectedCollection } = get();
    set({ selectedCollection: { ...config, spec: spec || selectedCollection?.spec } });
  },
  setCollectionConfig: value => set({ collectionConfig: value }),
  updateBuildingBlockSliceState: ({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft }) => {
    const { collections, infoPopovers } = get();
    const updatedConfigRef = Object.keys(draftAssistantConfig).length ? draftConfigRef : configRef;
    set({
      buildDraftResponse: { status: "", url: "" },
      collectionAssistantConfig: draftAssistantConfig,
      currentCollectionAssistantConfig: draftAssistantConfig,
      collections: {
        ...collections,
        specRef,
        configRef: updatedConfigRef,
        currentConfig: updatedConfigRef
      },
      draft,
      infoPopovers: { ...infoPopovers, draftExpiration: true }
    });
  },
  hasSpotCollection: () => {
    const { currentCollectionAssistantConfig } = get();
    return currentCollectionAssistantConfig?.skills?.some(collectionObj => collectionObj?.configuration?.key === collectionNames.SKILL.SPOT.WEB);
  },
  setFailedToUpdateDraft: (value) => {
    set({ failedToUpdateDraft: value });
  },
  clearCollections: () => {
    set({
      collectionConfig: {},
      originalCollectionConfig: {},
      collectionSpec: null,
      selectedCollection: {},
      collections: null,
      collectionAssistantConfig: {},
      collectionOriginalAssistantConfig: {},
      isPageLoading: true,
      validationResults: { live: {}, draft: {}, current: {} }
    });
  },

  getConnectivityTestResults: async (path) => {
    const { selectedAssistantId, collectionName, collectionAssistantConfig, userData, selectedCollection } = get();
    const { configuration: { key: collectionKey }, key: collectionInstanceKey } = selectedCollection;

    datadogRum.addAction(CUSTOM_ACTIONS.TEST_CONNECTION.CLICKED, {
      selectedAssistantId,
      user: userData.email
    });

    try {
      const testResults = await post(`assistants/collections/${selectedAssistantId}/${collectionName}/${collectionKey}/${collectionInstanceKey}/${path}/test-connectivity`, {
        assistant_configuration: collectionAssistantConfig,
        instance_config: selectedCollection,
        userData: { name: userData.name, email: userData.email }
      });

      datadogRum.addAction(CUSTOM_ACTIONS.TEST_CONNECTION.SUCCEEDED, {
        selectedAssistantId,
        user: userData.email
      });

      return testResults;
    } catch (e) {
      datadogRum.addAction(CUSTOM_ACTIONS.TEST_CONNECTION.FAILED, {
        selectedAssistantId,
        user: userData.email
      });
      console.error("Failed to get connectivity test results", e);
      return { error: true };
    }
  }
});

export default createCollectionsSlice;
