import "./styles/App.css";
import "./fonts/fonts.css";
import { FronteggProvider } from "@frontegg/react";
import { HYRO_THEME } from "@hyro/ui";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { useLocation } from "react-router-dom";

import { ClientSocketProvider } from "./contexts/ClientSocketContext";
import { DateTimeProvider } from "./contexts/DateTimeContext";
import initDatadog from "./datadog";
import InitializePermissions from "./InitializePermissions";
import { APP_HEADER_HEIGHT, APP_MOBILE_HEADER_HEIGHT } from "./layout/consts";
import ErrorBoundary from "./layout/ErrorBoundries";
import HeaderSkeleton from "./layout/Header/HeaderSkeleton";
import useAppStore from "./stores/appStore";
import { showHeaderSkeletonSelector } from "./stores/selectors/appSelectors";
import { ENVIRONMENTS, FRONTEGG_DASHBOARD_BASE_URL, FRONTEGG_DASHBOARD_CLIENT_ID } from "./utils";

let theme = createTheme();
const MUI_OVERRIDES = {
  mixins: {
    toolbar: {
      [theme.breakpoints.down("sm")]: {
        minHeight: APP_MOBILE_HEADER_HEIGHT
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: APP_HEADER_HEIGHT
      },
      backgroundColor: "#ffffff"
    }
  }
};

theme = createTheme({ ...HYRO_THEME, ...MUI_OVERRIDES });

if (import.meta.env.VITE_ENV === ENVIRONMENTS.PRODUCTION) {
  initDatadog();
}

const queryClient = new QueryClient({});

const App = () => {
  const location = useLocation();
  const showHeaderSkeleton = useAppStore(showHeaderSkeletonSelector);
  const contextOptions = {
    baseUrl: FRONTEGG_DASHBOARD_BASE_URL,
    clientId: FRONTEGG_DASHBOARD_CLIENT_ID
  };

  const authOptions = {
    keepSessionAlive: true
  };

  return (
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox
      authOptions={authOptions}
    >
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {showHeaderSkeleton ? <HeaderSkeleton /> : null}
            <ClientSocketProvider>
              <DateTimeProvider>
                <ErrorBoundary key={location.pathname}>
                  <InitializePermissions />
                </ErrorBoundary>
              </DateTimeProvider>
            </ClientSocketProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </FronteggProvider>
  );
};

export default App;
