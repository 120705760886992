/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { default as gamla } from "gamlajs";

import { accountsService, botPortalService, usersService } from "../../services";
import * as api from "../../services/api";
import { DRAFT_STATES, NOTIFICATIONS_STATES } from "../../utils";

export const SYSTEM_MODE_KEY = "systemMode";
export const PREVIOUS_SYSTEM_MODE_KEY = "previousSystemMode";
export const SYSTEM_MODES = {
  READ_ONLY: "READ_ONLY",
  EDIT: "EDIT",
  PREVIEW: "PREVIEW"
};

const createAppSlice = (set, get) => ({
  systemMode: localStorage.getItem(SYSTEM_MODE_KEY) || SYSTEM_MODES.READ_ONLY,
  previousSystemMode: localStorage.getItem(PREVIOUS_SYSTEM_MODE_KEY) || "",
  openWidgetInitially: localStorage.getItem(SYSTEM_MODE_KEY) === SYSTEM_MODES.PREVIEW,
  isWebSnippetOpen: false,
  isDiscardingChanges: false,
  pageName: "",
  showHeaderSkeleton: true,
  userData: {},
  userPermissions: {},
  selectedAssistantId: null,
  assistants: null,
  fetchingAssistants: true,
  configurationId: null,
  notifications: null,
  isAssistantPreviewable: false,
  selectedAccountId: null,
  accounts: null,
  isLoggedInToLooker: false,
  additionalBreadcrumbs: [],
  couldNotSetUpAssistant: false,
  hasPageErrorType: null,

  setPageError: (errorOrigin, error) => {
    console.error(`${errorOrigin ?? "Error"}: `, error?.response?.statusText);
    set({ hasPageErrorType: error.response.status });
  },

  setNotifications: (notifications) => set({ notifications }),
  setSystemMode: (newSystemMode) => {
    const {
      systemMode,
      updateConfigAndTriplets
    } = get();
    updateConfigAndTriplets(newSystemMode);
    localStorage.setItem("systemMode", newSystemMode);
    localStorage.setItem("previousSystemMode", systemMode);
    set({
      systemMode: newSystemMode,
      previousSystemMode: systemMode
    });
  },

  updateConfigAndTriplets: (newSystemMode) => {
    const {
      systemMode: storeSystemMode,
      currentTriplets,
      originalTriplets,
      collectionOriginalAssistantConfig,
      collectionAssistantConfig,
      collections,
      collectionConfig,
      originalCollectionConfig,
      collectionSpec,
      validationResults,
      draft
    } = get();
    const isPreviewMode = newSystemMode === SYSTEM_MODES.PREVIEW || storeSystemMode === SYSTEM_MODES.PREVIEW;
    if (isPreviewMode) return;
    const isEditMode = (newSystemMode || storeSystemMode) === SYSTEM_MODES.EDIT;
    const updatedValidationResults = isEditMode && draft ? validationResults.draft : validationResults.live;
    const updatedCurrentSelectedConfig = isEditMode && draft ? collectionConfig : originalCollectionConfig;
    const updatedCurrentAssistantConfig = isEditMode && draft ? collectionAssistantConfig : collectionOriginalAssistantConfig;
    const updatedCurrentTriplets = isEditMode && draft && currentTriplets?.length ? currentTriplets : originalTriplets;
    const updatedCurrentCollections = isEditMode && draft ? {
      ...collections,
      currentConfig: collections?.configRef
    } : { ...(collections || {}), currentConfig: collections?.originalConfig };
    set({
      currentCollectionAssistantConfig: updatedCurrentAssistantConfig,
      selectedCollection: updatedCurrentSelectedConfig ? { ...updatedCurrentSelectedConfig, spec: collectionSpec } : null,
      collections: updatedCurrentCollections,
      displayedTriplets: updatedCurrentTriplets,
      validationResults: {
        ...validationResults,
        current: updatedValidationResults
      }
    });
  },

  setIsWebSnippetOpen: isWebSnippetOpen => set({ isWebSnippetOpen }),
  setPageName: value => set({ pageName: value }),
  setShowHeaderSkeleton: value => set({ showHeaderSkeleton: value }),

  isReadOnlyMode: () => {
    const { draft, isDraftOwner } = get();
    return (
      draft?.status === DRAFT_STATES.READ_ONLY
      || (draft?.userEmail && !isDraftOwner())
    );
  },

  submittedDraft: () => {
    const { notifications } = get();
    return notifications?.find(({ status, type }) =>
      [
        NOTIFICATIONS_STATES.IN_PROGRESS,
        NOTIFICATIONS_STATES.IN_PROGRESS_FAILED
      ].includes(status) && type !== "Build"
    );
  },

  isDraftOwner: () => {
    const { userData, draft } = get();
    return draft?.userEmail === userData?.email;
  },

  setIsLoggedInToLooker: (isLoggedIn) => {
    set({ isLoggedInToLooker: isLoggedIn });
  },

  setDashboardSettings: (dashboardSettings) => {
    set({ dashboardSettings });
  },

  setSelectedAccount: async (selectedAccountId) => {
    set({ selectedAccountId, fetchingAssistants: true });
    let assistants;

    try {
      assistants = (await accountsService.findAccountAssistants()).map(
        gamla.renameKeys({ friendlyName: "name", roomId: "id" })
      );
    } catch (e) {
      assistants = [];
    }
    set({ assistants, fetchingAssistants: false });
  },

  setAccountWithoutAssistants: (selectedAccountId) => {
    set({ selectedAccountId });
  },

  setupAccounts: async () => {
    const accounts = await accountsService.findAccounts();
    set({ accounts });
  },

  setupPermissions: async (userData) => {
    const { setPageError } = get();
    set({ userData });

    try {
      const userPermissions = await usersService.userPermissions();
      set({ userPermissions });
    } catch (e) {
      setPageError("Failed in setting up permissions", e);
    }
  },

  setSelectedAssistantId: async (selectedAssistantId) => {
    if (!selectedAssistantId) {
      set({
        selectedAssistantId: null,
        isLoggedInToLooker: false,
        notifications: null
      });
      return;
    }
    const {
      initTriplets,
      clearTriplets,
      resetConversationsState,
      getKnowledgeGraph,
      clearCollections,
      getValidationResults
    } = get();
    clearTriplets();
    resetConversationsState();
    clearCollections();
    set({
      selectedAssistantId,
      isLoggedInToLooker: false,
      notifications: null
    });

    try {
      const notifications = await api.get(`/assistants/${selectedAssistantId}/notifications`);
      const configurationId = await botPortalService.getClientName();
      set({
        notifications,
        configurationId
      });
      await initTriplets();
      getKnowledgeGraph();
      getValidationResults();
    } catch (e) {
      console.error("Failed in setting selected assistant id: ", e.response.statusText);
    }
  },

  hasPermission: (permission) => {
    const { userPermissions } = get();
    return userPermissions?.permissions?.includes(permission);
  },

  hasAccessToAccount: () => {
    const { userPermissions, selectedAccountId } = get();
    return userPermissions?.accounts?.includes(selectedAccountId);
  },

  hasAccountPermission: (permission) => {
    const { hasAccessToAccount, hasPermission } = get();
    return hasAccessToAccount() && hasPermission(permission);
  },

  updateSelectedAssistant: (updatedAssistant) => {
    const { selectedAssistantId, assistants } = get();
    const newAssistants = assistants.map(assistant =>
      assistant.id === selectedAssistantId ? updatedAssistant : assistant
    );
    set({ assistants: newAssistants });
  }
});

export default createAppSlice;
