import Box from "@mui/material/Box";
import React, { useEffect } from "react";

import InsightsBase from "./InsightsBase";
import useAppStore from "../../../stores/appStore";
import {
  isLoggedInToLookerSelector,
  setIsLoggedInToLookerSelector
} from "../../../stores/selectors/appSelectors";

const InsightsTab = ({ embeddedDashboardData, nonLoginEmbedUrl, dashboardName }) => {
  const isLoggedInToLooker = useAppStore(isLoggedInToLookerSelector);
  const setIsLoggedInToLooker = useAppStore(setIsLoggedInToLookerSelector);

  useEffect(
    () => () => {
      if (!isLoggedInToLooker) setIsLoggedInToLooker(true);
    },
    [isLoggedInToLooker, setIsLoggedInToLooker]
  );
  return (
    <Box mt={3}>
      <InsightsBase
        key={dashboardName}
        embedUrl={
          isLoggedInToLooker ? nonLoginEmbedUrl : embeddedDashboardData.embedUrl
        }
      />
    </Box>
  );
};

export default InsightsTab;
