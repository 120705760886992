import { Button, styled } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import useAppStore from "../../stores/appStore";
import {
  assistantIdSelector,
  assistantsSelector,
  selectedAccountIdSelector
} from "../../stores/selectors/appSelectors";
import {
  clearCollectionBreadCrumbsSelector,
  collectionBreadCrumbsLastItemSelector,
  collectionBreadCrumbsSelector, ignoreUrlChangeSelector,
  removeLastItemFromCollectionBreadCrumbsSelector,
  selectedCollectionSelector, setIgnoreUrlChangeSelector
} from "../../stores/selectors/collectionsSelectors";
import { capitalize, capitalizeAndCleanUnderscore, HOME } from "../../utils";

const StyledLink = styled(Link)(({ theme }) => ({
  "textDecoration": "none",
  "display": "flex",
  "&:hover": {
    textDecoration: "underline",
    textDecorationColor: theme.palette.text.secondary
  }
}));

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const assistants = useAppStore(assistantsSelector);
  const accountId = useAppStore(selectedAccountIdSelector);
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const selectedAssistant = assistants.find(assistant => assistant.id === selectedAssistantId);
  const selectedCollection = useAppStore(selectedCollectionSelector);
  const collectionBreadCrumbs = useAppStore(collectionBreadCrumbsSelector);
  const removeLastItemFromCollectionBreadCrumbs= useAppStore(removeLastItemFromCollectionBreadCrumbsSelector);
  const collectionBreadCrumbsLastItem = useAppStore(collectionBreadCrumbsLastItemSelector);
  const clearCollectionBreadCrumbs = useAppStore(clearCollectionBreadCrumbsSelector);
  const ignoreUrlChange = useAppStore(ignoreUrlChangeSelector);
  const setIgnoreUrlChange = useAppStore(setIgnoreUrlChangeSelector);
  const baseUrl = `/${accountId}/${selectedAssistant?.id}/`;
  const pageName = location.pathname.replace(baseUrl, "")?.split("/")?.[0];

  const pathName = selectedCollection?.display_key;
  const breadcrumbs = [
    capitalize(selectedAssistant?.name).replace(/[_-]/, " "),
    capitalize(pageName).replace(/[_-]/, " "),
    ...(selectedCollection ? [capitalizeAndCleanUnderscore(pathName)] : [])
  ];

  const handleBackClick = () => {
    const lastItem = collectionBreadCrumbsLastItem;
    removeLastItemFromCollectionBreadCrumbs();
    setIgnoreUrlChange(true);
    navigate(lastItem?.url);
  };

  useEffect(() => {
    const currentUrl = location.pathname;

    if (collectionBreadCrumbs.length === 0) {
      return;
    }
    const lastItemUrl = collectionBreadCrumbsLastItem?.url;

    if (currentUrl !== lastItemUrl && !ignoreUrlChange) {
      clearCollectionBreadCrumbs();
    }
    setIgnoreUrlChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return selectedAssistant
    ? (
      collectionBreadCrumbs.length > 0 ?
        <Button
          onClick={handleBackClick}
          startIcon={<ArrowLeftIcon size={"20"} stroke={"#2D2C44B2"} />}
          sx={{ ml: "-12px", px: "12px", lineHeight: "20px", color: "#363554" }}>
          Back to {collectionBreadCrumbsLastItem?.collection} - {collectionBreadCrumbsLastItem?.name}
        </Button>
        :
        <Breadcrumbs aria-label="breadcrumb" sx={{ ".MuiBreadcrumbs-separator": { fontSize: "14px" } }}>
          {breadcrumbs.map((breadcrumb, index) => {
            if (!breadcrumb) return;
            const isLastBreadcrumb = breadcrumbs.length - 1 === index;
            const path
            = index === 0 ? HOME : [...breadcrumbs].slice(1, index + 1).join("/");
            // eslint-disable-next-line consistent-return
            return (
              <StyledLink
                key={"breadcrumb-" + breadcrumb}
                underline="hover"
                color="inherit"
                to={`${baseUrl}${path.toLowerCase().split(" ").join("-")}`}
                style={{ pointerEvents: isLastBreadcrumb ? "none" : "" }}
              >
                <Typography
                  sx={{ lineHeight: "24px" }}
                  variant="body2"
                  color={`text.${isLastBreadcrumb ? "primary" : "secondary"}`}
                  data-testid={`breadcrumb-${breadcrumb}`}
                >
                  {breadcrumb}
                </Typography>
              </StyledLink>
            );
          })}
        </Breadcrumbs>
    )
    : null;
};

export default BreadcrumbsComponent;
