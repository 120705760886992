import { default as gamla } from "gamlajs";
import {
  applySpec,
  equals,
  filter,
  groupBy,
  head,
  ifElse,
  is,
  isEmpty,
  join,
  map,
  nth,
  pipe,
  prop,
  uniq,
  unless,
  values,
  when,
  zipObj
} from "ramda";

import { object, subject } from "../../triplets";
import { CONCEPT_TYPE, sortAlphabetically } from "../../utils";

const NODE_COLORS = [
  "#D640EF",
  "#9A40EF",
  "#6040EF",
  "#4077EF",
  "#3DE5B0",
  "#40B2EF",
  "#EDCF66",
  "#A7E03C",
  "#40ECEF",
  "#93B5F4",
  "#E8B23F",
  "#F6AB93",
  "#9FDFF7",
  "#A295DD",
  "#907AFF",
  "#C8BDFF",
  "#F4836A",
  "#CBEA7C",
  "#C691F3",
  "#45BDA0",
  "#ED994C",
  "#DE7EEE",
  "#BB6B96",
  "#8DE894",
  "#A8CCF4",
  "#EB93F2",
  "#69C7C9",
  "#B3BFDB",
  "#C3B78D",
  "#BFC1C1",
  "#FF9292",
  "#898AA3",
  "#D89E94",
  "#B0E0C8",
  "#7583F7",
  "#3CE076",
  "#B0E0C8",
  "#AA79AB",
  "#ADD080",
  "#5E98B7",
  "#5F98E8",
  "#BB9F75",
  "#7891DC"
];

const filterByRelation = relation => filter(pipe(nth(1), equals(relation)));

const displayObjs = pipe(
  filterByRelation("concept/display"),
  unless(isEmpty, pipe(head, nth(2)))
);
const ellipsisLabel = val =>
  val.length > 40 ? val.slice(0, 40) + "..." : val;

const getTypes = pipe(filterByRelation(CONCEPT_TYPE), map(object), uniq);

const typeToColorProperty = object =>
  Object.keys(object).reduce(
    (acc, key) => Object.assign(acc, { [object[key]]: { color: key } }),
    {}
  );

const getGroupOptions = pipe(
  sortAlphabetically,
  zipObj(NODE_COLORS),
  typeToColorProperty
);

const nodeIdToDisplay = ifElse(
  pipe(displayObjs, isEmpty),
  pipe(head, head, nodeId => `<node_id: ${nodeId}>`),
  pipe(displayObjs, prop("text"))
);

const makeNodes = pipe(
  groupBy(subject),
  values,
  map(
    applySpec({
      id: pipe(head, head),
      group: pipe(getTypes, head), // We arbitrarily take the first type as the node's group.
      label: pipe(nodeIdToDisplay, ellipsisLabel),
      fullDescription: nodeIdToDisplay,
      title: pipe(getTypes, join(", "), nodeTypes => `types: ${nodeTypes}`)
    })
  )
);

const makeEdges = pipe(
  filter(pipe(nth(1), gamla.contains(["concept/association", CONCEPT_TYPE]))),
  map(
    applySpec({
      from: subject,
      to: object
    })
  )
);

const makeGraphVisJSON = pipe(
  prop("triplets"),
  applySpec({
    nodes: makeNodes,
    edges: makeEdges,
    typeNodes: getTypes
  })
);

const makeGraphTriplets = pipe(
  prop("triplets"),
  map(map(when(is(Object), prop("text"))))
);

// Get the 'main' graph hash otherwise the newest one available
const getMainKgHashFromVersionFile = versionFile => {
  const sortedVersionFile = Object.entries(versionFile)
    .sort(([, a], [, b]) => new Date(b.last_run_timestamp) - new Date(a.last_run_timestamp))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const s = Object.keys(sortedVersionFile).find(key => sortedVersionFile[key].label === "main") ||
    Object.keys(sortedVersionFile).find(key => sortedVersionFile[key].label === "regular");
  return sortedVersionFile[s]
    ?.result_hash || sortedVersionFile[Object.keys(sortedVersionFile)[0]]?.result_hash;
};

export {
  NODE_COLORS,
  filterByRelation,
  displayObjs,
  ellipsisLabel,
  getTypes,
  typeToColorProperty,
  getGroupOptions,
  nodeIdToDisplay,
  makeNodes,
  makeEdges,
  makeGraphVisJSON,
  makeGraphTriplets,
  getMainKgHashFromVersionFile
};
