import { List, Paper, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as React from "react";

import ListItemContent from "./ListItemContent";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent"
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    margin: 0,
    padding: `0 ${theme.spacing()}`
  }
}));

const SideMenuHeader = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(3.5)}`
}));

const SingleListItem = ({ handleClick, navItemIsOpen, open, navItem, ...props }) => {
  const listItemContentProps = navItem => ({
    navItem,
    open,
    navItemIsOpen,
    ...props,
    showIcon: true
  });

  return (
    <StyledListItem onClick={handleClick(navItem, navItemIsOpen)}>
      {open
        ? <ListItemContent {...listItemContentProps(navItem)} />
        : (
          <StyledTooltip
            placement="right-start"
            title={(
              <Paper elevation={3}>
                <List>
                  <SideMenuHeader variant="overline">
                    {navItem.text}
                  </SideMenuHeader>
                  {navItem.children?.map(navItem => (
                    <StyledListItem
                      key={navItem.key}
                      onClick={handleClick(navItem)}
                    >
                      <ListItemContent
                        {...{
                          ...listItemContentProps(navItem),
                          open: true,
                          showIcon: false
                        }}
                      />
                    </StyledListItem>
                  ))}
                </List>
              </Paper>
            )}
          >
            {/* The "div" component is needed here (related to Tooltip component implementation). */}
            <div>
              <ListItemContent {...listItemContentProps(navItem)} />
            </div>
          </StyledTooltip>
        )}
    </StyledListItem>
  );
};

export default SingleListItem;
