import { useState } from "react";

import {
  FIRST_STEP_BUTTON_TEXT,
  FIRST_STEP_SUBTITLE,
  FIRST_STEP_TITLE,
  LABEL,
  SECOND_STEP_BUTTON_TEXT,
  SECOND_STEP_SUBTITLE,
  SECOND_STEP_TITLE
} from "./DraftHeaderConsts";

const usePublishDialog = ({ handleSubmit, onClose }) => {
  let dialogContent = {};
  const [step, setStep] = useState(0);
  const [changeDescription, setChangeDescription] = useState("");

  const handlePublish = () => {
    handleSubmit({ changeDescription });
    onClose();
  };

  if (step === 0) {
    dialogContent = {
      step,
      title: FIRST_STEP_TITLE,
      subtitle: FIRST_STEP_SUBTITLE,
      actionButtonText: FIRST_STEP_BUTTON_TEXT,
      handlePublish: () => setStep(1),
      handleCancel: () => onClose()
    };
  }
  else if (step === 1) {
    dialogContent = {
      step,
      title: SECOND_STEP_TITLE,
      subtitle: SECOND_STEP_SUBTITLE,
      actionButtonText: SECOND_STEP_BUTTON_TEXT,
      label: LABEL,
      changeDescription,
      setChangeDescription,
      handlePublish,
      disablePublish: step === 1 && !changeDescription,
      handleCancel: () => {
        onClose();
        setStep(0);
      }
    };
  }

  return { dialogContent };
};

export default usePublishDialog;
