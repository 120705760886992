import CheckIcon from "@mui/icons-material/Check";
import { List, Popover, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { darken, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Jdenticon from "react-jdenticon";
import { useNavigate } from "react-router-dom";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";

import { DIALOG_VARIANTS, EXIT_ASSISTANT_DIALOG } from "../components/common/Dialogs/DialogConsts";
import { useCurrentAssistantPage } from "../hooks";
import UnfoldIcon from "../icons/UnfoldIcon";
import useAppStore from "../stores/appStore";
import {
  assistantIdSelector,
  assistantsSelector,
  selectedAccountIdSelector,
  selectedAssistantSelector,
  systemModeSelector
} from "../stores/selectors/appSelectors";
import { setSelectedCollectionSelector } from "../stores/selectors/collectionsSelectors";
import { isEditModeSelector, isFirstDraftSelector } from "../stores/selectors/tripletsSelectors";
import { SYSTEM_MODES } from "../stores/slices/createAppSlice";
import { BUILDING_BLOCKS, CHANNEL_TO_DISPLAY_NAME, SKILLS } from "../utils";

const ButtonContainer = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5)
}));

const StyledButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "hover"
})(({ theme, hover }) => ({
  "maxWidth": "100%",
  "padding": theme.spacing(),
  "borderRadius": theme.shape.borderRadius,
  "transition": "none",
  "&:hover": hover
    ? {
      backgroundColor: darken(theme.palette.background.default, 0.04)
    }
    : {
      backgroundColor: "transparent",
      cursor: "default"
    },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  }
}));

const StyledButtonIcon = styled(ListItemIcon)(() => ({
  minWidth: 0,
  svg: {
    verticalAlign: "top"
  }
}));

const ChannelListItem = styled(ListItemText)(() => ({
  marginBlock: 0,
  display: "flex",
  alignItems: "center",
  height: "14.5px",
  marginTop: "2px",
  marginBottom: "2px"
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "block",
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "selected"
})(({ selected, theme }) => ({
  "minHeight": 36,
  "justifyContent": open ? "initial" : "center",
  "padding": `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  "borderRadius": theme.shape.borderRadius,
  "textOverflow": "ellipsis",
  "&:hover": {
    backgroundColor: darken(theme.palette.background.default, 0.04)
  },
  ...(selected && {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  })
}));

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== "selected"
})(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  ...(selected && { justifyContent: "end" }),
  minWidth: 0,
  marginLeft: theme.spacing(1),
  svg: {
    verticalAlign: "top"
  }
}));

const TextContainer = styled("div")(() => ({
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden"
}));

const ChannelText = styled("span")(({ theme, isList, selected }) => ({
  color: (isList && selected) ? theme.palette.primary.main : theme.palette.action.active
}));

// eslint-disable-next-line max-lines-per-function, complexity
const AssistantSelector = ({ isNavPanelOpen }) => {
  const isEditMode = useAppStore(isEditModeSelector);
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const setSelectedCollection = useAppStore(setSelectedCollectionSelector);
  const navigate = useNavigate();
  const currentPage = useCurrentAssistantPage();
  const accountId = useAppStore(selectedAccountIdSelector);
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const assistants = useAppStore(assistantsSelector).sort();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedAssistant = useAppStore(selectedAssistantSelector);
  const open = Boolean(anchorEl);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [upcomingAssistantId, setUpcomingAssistantId] = useState(null);
  const systemMode = useAppStore(systemModeSelector);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToNewAssistant = assistantId =>{
    let page;

    if (currentPage.includes(SKILLS)) {
      page = SKILLS;
    } else if (currentPage.includes(BUILDING_BLOCKS)) {
      page = BUILDING_BLOCKS;
    } else {
      page = currentPage;
    }
    navigate(`/${accountId}/${assistantId}/${page}`);
  };

  const handleSelectedAssistantChange = (assistantId) => {
    if (isEditMode && !isFirstDraft) {
      setUpcomingAssistantId(assistantId);
      navigateToNewAssistant(assistantId);
    }
    else {
      setSelectedCollection(null);
      navigateToNewAssistant(assistantId);
    }
    handleMenuClose();
  };

  const renderNameAndChannel = (assistant, isList, selected) => (<TextContainer>
    <ListItemText style={{ marginBlock: 0 }}>
      <Typography variant="body2" noWrap>
        {assistant.name}
      </Typography>
    </ListItemText>
    <ChannelListItem>
      <Typography variant="caption" noWrap>
        <ChannelText noWrap isList={isList} selected={selected}>
          {CHANNEL_TO_DISPLAY_NAME[assistant.channel] || "Unknown Channel"}
        </ChannelText>
      </Typography>
    </ChannelListItem>
  </TextContainer>);

  return selectedAssistant
    ? (
      <>
        <ButtonContainer>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "180px"
                }
              }
            }}
            title={selectedAssistant.name} placement="bottom"
          >
            <StyledButton
              hover={assistants.length > 1 && systemMode !== SYSTEM_MODES.EDIT}
              {...(assistants.length > 1 && systemMode !== SYSTEM_MODES.EDIT && { onClick: handleMenuOpen })}
              disableRipple
            >
              <StyledButtonIcon sx={{ mr: "8px" }}>
                <Jdenticon value={selectedAssistant.id} size="32"/>
              </StyledButtonIcon>
              {renderNameAndChannel(selectedAssistant, false)}
              {assistants.length > 1 && isNavPanelOpen && systemMode !== SYSTEM_MODES.EDIT && (
                <StyledButtonIcon sx={{ ml: "2px" }}>
                  <UnfoldIcon fontSize="small"/>
                </StyledButtonIcon>
              )}
            </StyledButton>
          </Tooltip>
        </ButtonContainer>
        <Popover
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          elevation={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{
            style: {
              maxHeight: 300,
              maxWidth: 400
            }
          }}
        >
          <List sx={{ overflow: "auto" }}>
            {assistants.map(assistant => {
              const selected = assistant.id === selectedAssistantId;
              return <StyledListItem key={assistant.id}>
                <StyledListItemButton
                  onClick={() => handleSelectedAssistantChange(assistant.id)}
                  selected={selected}
                >
                  <ListItemIcon sx={{ height: "40px" }}>
                    <Jdenticon value={assistant.name} size="40"/>
                  </ListItemIcon>
                  {renderNameAndChannel(assistant, true, selected)}
                  {selected && (
                    <StyledListItemIcon {...{ selected: true }}>
                      <CheckIcon/>
                    </StyledListItemIcon>
                  )}
                </StyledListItemButton>
              </StyledListItem>;
            })}
          </List>
        </Popover>
        <Dialog
          open={isExitDialogOpen}
          title={EXIT_ASSISTANT_DIALOG.title}
          submitButtonText={EXIT_ASSISTANT_DIALOG.discardButton}
          cancelButtonText={EXIT_ASSISTANT_DIALOG.keepEditingButton}
          variant={DIALOG_VARIANTS.transactional}
          handleSubmit={() => {
            navigateToNewAssistant(upcomingAssistantId);
            handleMenuClose();
            setIsExitDialogOpen(false);
          }}
          handleCancel={() => setIsExitDialogOpen(false)}
        >
          {EXIT_ASSISTANT_DIALOG.content}
        </Dialog>
      </>
    )
    : null;
};

export default AssistantSelector;
