import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  getAdminNavItems,
  getGeneralNavItems,
  getHyroNavItems
} from "./Navigation/navigationItems";
import useAppStore from "../stores/appStore";
import {
  assistantIdSelector,
  selectedAccountIdSelector
} from "../stores/selectors/appSelectors";

const PageTitle = () => {
  const { pathname } = useLocation();
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const accountId = useAppStore(selectedAccountIdSelector);
  const { conversationId } = useParams();

  const currentNav = [
    ...getGeneralNavItems(accountId, selectedAssistantId, conversationId),
    ...getAdminNavItems(),
    ...getHyroNavItems(accountId, selectedAssistantId)
  ].reduce((prev, navItem) => {
    if (navItem?.children) {
      const selectedChildNavItem = navItem.children.find(
        childNav => childNav.url === pathname
      );
      return selectedChildNavItem ? selectedChildNavItem : prev;
    }
      return navItem.url === pathname ? navItem : prev;

  }, null);

  return currentNav && <Box>
    <Typography variant="h5" component="h1" data-testid={`page-title-${currentNav.text}`}>{currentNav.text}</Typography>
    <Typography variant="body2" sx={{ mt: 0.5 }}>{currentNav.description}</Typography>
  </Box>;
};

export default PageTitle;
