import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { FETCH_QUERY_KEYS } from "src/queries/consts";
import { fetchDashboardSettings } from "src/queries/services/settings";

export const useFetchDashboardSettings = ({ fullObject = false } = {}) => {
  const { pathname } = useLocation();
  return useQuery({
    queryKey: [FETCH_QUERY_KEYS.DASHBOARD_SETTINGS, pathname],
    queryFn: () => fetchDashboardSettings(pathname.includes("admin")),
    select: (data) => (fullObject ? data : data?.settings),
    staleTime: 3000
  });
};