import { Typography, Stack } from "@mui/material";
import React from "react";

const HyroTableTitle = ({ children, title, rowsLength }) => (
  <Stack sx={{ justifyContent: "flex-start", alignItems: "center", flexDirection: "row" }}>
    {title ? <Typography variant="h6">{title}</Typography> : null}
    <Typography variant="body2" sx={{ ml: 0.5, translate: "0 1px" }}>{`(${rowsLength || 0})`}</Typography>

    {children}
  </Stack>
);

export default HyroTableTitle;
