import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import { prop } from "ramda";

export const openWidgetInitiallySelector = state => state.openWidgetInitially;
export const systemModeSelector = state => state.systemMode;
export const previousSystemModeSelector = state => state.previousSystemMode;
export const hasPageErrorTypeSelector = state => state.hasPageErrorType;
export const setSystemModeSelector = state => state.setSystemMode;
export const updateConfigAndTripletsSelector = state => state.updateConfigAndTriplets;
export const isWebSnippetOpenSelector = state => state.isWebSnippetOpen;
export const isDiscardingChangesSelector = state => state.isDiscardingChanges;
export const setIsWebSnippetOpenSelector = state => state.setIsWebSnippetOpen;
export const showHeaderSkeletonSelector = state => state.showHeaderSkeleton;
export const setHeaderSkeletonSelector = state => state.setShowHeaderSkeleton;
export const setPageNameSelector = state => state.setPageName;
export const pageNameSelector = state => state.pageName;
export const isAdminSelector = state =>
  state.userPermissions?.permissions?.includes(
    USER_PERMISSIONS.ADMIN_PANEL.READ
  );
export const assistantIdSelector = state => state.selectedAssistantId;
export const accountNameSelector = state =>
  state.accounts?.find(({ accountId }) => accountId === state.selectedAccountId)
    ?.name;
export const assistantsSelector = state => state.assistants;
export const selectedAssistantSelector = state =>
  state?.assistants?.find(
    assistant => assistant?.id === state?.selectedAssistantId
  );
export const allAssistantIdsSelector = state =>
  state.assistants?.map(prop("id"));
export const accountIdsSelector = state =>
  state.accounts?.map(prop("accountId"));
export const accountsSelector = state => state.accounts;
export const isReadOnlyModeSelector = state => state.isReadOnlyMode();
export const submittedDraftSelector = state => state.submittedDraft();
export const isDraftOwnerSelector = state => state.isDraftOwner();
export const managementAccountsSelector = state => state.managementAccounts;
export const accountManagementErrorSelector = state =>
  state.accountManagementError;
export const accountManagementErrorTextSelector = state =>
  state.accountManagementErrorText;
export const getAccountsAdminSelector = state => state.getAccountsAdmin;
export const createAccountSelector = state => state.createAccount;
export const editAccountSelector = state => state.editAccount;
export const deleteAccountSelector = state => state.deleteAccount;
export const setAccountManagementErrorSelector = state =>
  state.setAccountManagementError;
export const isLoggedInToLookerSelector = state => state.isLoggedInToLooker;
export const setIsLoggedInToLookerSelector = state =>
  state.setIsLoggedInToLooker;
export const setSelectedAccountSelector = state => state.setSelectedAccount;
export const setAccountWithoutAssistantsSelector = state => state.setAccountWithoutAssistants;
export const userPermissionsSelector = state => state.userPermissions;
export const notificationsSelector = state => state.notifications;
export const userDataSelector = state => state.userData;
export const setAssistantIdSelector = state => state.setSelectedAssistantId;
export const setupPermissionsSelector = state => state.setupPermissions;
export const setupAccountsSelector = state => state.setupAccounts;
export const getAccountsSelector = state => state.accounts;
export const setIsAssistantPreviewableSelector = state =>
  state.setIsAssistantPreviewable;
export const isAssistantPreviewableSelector = state =>
  state.isAssistantPreviewable;
export const selectedAccountIdSelector = state => state.selectedAccountId;
export const fetchingAssistantsSelector = state => state.fetchingAssistants;
export const hasAccountPermissionSelector = state =>
  state.hasAccountPermission;
export const hasPermissionSelector = state => state.hasPermission;
export const updateSelectedAssistantSelector = state =>
  state.updateSelectedAssistant;

