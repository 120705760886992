import { Stack, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";
import Tooltip from "src/components/common/Tooltip";
import { DOWNLOAD_BTN_SIZES, DOWNLOAD_BTN_VARIANTS } from "src/utils";

import DownloadIcon from "../../icons/DownloadIcon";

const CSVLinkButton = styled(CSVLink)(({ disabled, variant, size }) => ({
  textDecoration: "none",
  ...(variant === DOWNLOAD_BTN_VARIANTS.OUTLINED && {
    border: "1px solid rgba(45, 44, 68, 0.24)",
    borderRadius: "8px",
    padding: size === DOWNLOAD_BTN_SIZES.LARGE ? "9px" : "7px",
    "&:hover": {
      backgroundColor: "#EBEBFD",
      borderColor: "#7583F7"
    }
  }),
  ...(disabled && {
    pointerEvents:"none",
    cursor: "default",
    border: "1px solid rgba(45, 44, 68, 0.12)",
    backgroundColor: "#FAFAFA"
  })
}));

// eslint-disable-next-line complexity
const DownloadButton = ({ fileName, fileHeaders, fileData, buttonText = "", disabled, variant = DOWNLOAD_BTN_VARIANTS.TEXT, size = DOWNLOAD_BTN_SIZES.MEDIUM }) => {
  const [hover, setHover] = React.useState(false);
  const theme = useTheme();
  return (
    <Tooltip title={disabled || Boolean(buttonText) ? "" : "Download"}>
      <CSVLinkButton
        filename={`${fileName}.csv`}
        data={fileData}
        headers={fileHeaders}
        disabled={disabled}
        variant={variant}
        size={size}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Stack
          gap={1}
          direction='row'
          alignItems='center'
        >
          <DownloadIcon stroke={disabled ? theme.palette.text.disabled : hover ? theme.palette.primary.main : theme.palette.action.active}/>
          {!!buttonText && <Typography
            sx={{ color: disabled ? theme.palette.text.disabled : hover ? theme.palette.primary.main : theme.palette.action.active }}
            variant='buttonMedium'>
            {buttonText}
          </Typography>}
        </Stack>
      </CSVLinkButton>
    </Tooltip>
  );
};

export default DownloadButton;
