export const retryAPICall = async (apiCall, retries = 1, onFinalError, delay = 3000) => {
  let tries = retries;

  const onError = (error) => {
    tries--;

    if (!tries) {
      return onFinalError(error);
    }

    return new Promise((resolve) => setTimeout(resolve, delay)).then(() => retryAPICall(apiCall, tries, onFinalError));
  };

  try {
    return await apiCall();
  } catch (e) {
    return onError(e);
  }
};
