import React from "react";

import ChipContainer from "./ChipContainer";
import ContainerWithEllipses from "./ContainerWithEllipses";
import DisplayReference from "./DisplayReference";
import ImageAvatar from "./ImageAvatar";
import { PUBLISH_STATUS, PUBLISH_STATUSES } from "./utils";
import useAppStore from "../../../stores/appStore";
import { canCreatePhoneNumbersSelector } from "../../../stores/KnowledgeEditor/knowledgeEditorSelectors";
import { FIELD_TYPES } from "../../../stores/KnowledgeEditor/utils";
import CreatePhoneNumber from "../ModifyEntity/CreatePhoneNumber";

const ColumnCell = ({ row, updateRow, columnSpec }) => {
  const { id, columnType, refEntity } = columnSpec;
  const canCreatePhoneNumbers = useAppStore(canCreatePhoneNumbersSelector);

  switch (columnType) {
  case PUBLISH_STATUS:
    return (
      <ContainerWithEllipses
        shouldHighlight={[
          PUBLISH_STATUSES.NEW,
          PUBLISH_STATUSES.EDITED
        ].includes(row[id])}
      >
        {row[id]}
      </ContainerWithEllipses>
    );
  case FIELD_TYPES.MULTIPLE_VALUES:
    return <ChipContainer values={row[id] || []} />;
  case FIELD_TYPES.IMAGE:
    return <ImageAvatar src={row[id]} />;
  case FIELD_TYPES.REF:
    return <DisplayReference value={row[id]} refEntity={refEntity} />;
  case FIELD_TYPES.PHONE_NUMBER:
    return (
      <CreatePhoneNumber
        currentPhoneNumber={row[id]}
        onGenerateNumber={value => updateRow({ ...row, [id]: value })}
        canCreateNumbers={canCreatePhoneNumbers}
      />
    );
  default:
    return <ContainerWithEllipses>{row[id]}</ContainerWithEllipses>;
  }
};

export default ColumnCell;
