import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useIsMobile, useStatusBarDisplayed } from "../../hooks";
import useAppStore from "../../stores/appStore";
import { hasAccountPermissionSelector, selectedAccountIdSelector } from "../../stores/selectors/appSelectors";
import AssistantHeader from "../Content/AssistantHeader";
import ErrorBoundary from "../ErrorBoundries";
import { AppWrapper, ContentWrapper } from "../LayoutWrappers";
import AssistantNavbar from "../Navigation/AssistantNavigation/AssistantNavbar";
import { filterNavItemByPermission, getGeneralNavItems, getHyroNavItems } from "../Navigation/navigationItems";

const AssistantLayout = ({ assistantId }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMediumSizeDevice = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const isMobile = useIsMobile();
  const accountId = useAppStore(selectedAccountIdSelector);
  const hasAccountPermission = useAppStore(hasAccountPermissionSelector);
  const [open, setOpen] = useState(true);
  const showStatusBar = useStatusBarDisplayed();

  const filterNavItemByAccountPermission
    = filterNavItemByPermission(hasAccountPermission);

  const generalNavItems = getGeneralNavItems(accountId, assistantId).filter(
    filterNavItemByAccountPermission
  );

  const hyroNavItems
    = hasAccountPermission(USER_PERMISSIONS.HYRO_TOOLS_SECTION.READ)
    && getHyroNavItems(accountId, assistantId).filter(
      filterNavItemByAccountPermission
    );

  const props = { generalNavItems, hyroNavItems, open, setOpen, showStatusBar };

  useEffect(() => {
    setOpen(!isMediumSizeDevice);
  }, [isMediumSizeDevice]);

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <AssistantNavbar {...props} showMinimizeButton={!isMediumSizeDevice} />
      )}
      <Suspense>
        <AppWrapper className="app" statusBarDisplayed={showStatusBar}>
          <ContentWrapper component="main">
            <ErrorBoundary key={location.pathname}>
              <AssistantHeader {...props} />
              <Outlet />
            </ErrorBoundary>
          </ContentWrapper>
        </AppWrapper>
      </Suspense>
    </div>
  );
};

export default AssistantLayout;
