/* eslint-disable no-mixed-operators */
import { MenuItem, Stack, Typography } from "@mui/material";
import { DataGrid, GridFooter, GridFooterContainer, GridPagination, useGridApiRef, gridClasses, GridCellModes } from "@mui/x-data-grid";
import React, { useState, useEffect, useRef } from "react";
import AddIcon from "src/icons/AddIcon";
import ThreeDotMenu from "src/pages/KnowledgeEditor/Table/ThreeDotMenu";
import NothingHereYet from "src/static/images/nothing_here_yet.svg";
import { getTextWidth } from "src/utils";

import { StyledContainer, AddNewRowButton, TextWrapper, StyledCellContainer } from "./Styles";
import useSingleClickEdit from "./useSingleClickEdit";

const FIRST_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 10;
const PAGINATION_INITIAL_STATE = { page: FIRST_PAGE, pageSize: DEFAULT_ROWS_PER_PAGE };
const DEFAULT_EMPTY_TEXT = "Nothing here yet";
const WIDTHS = {
  ARRAY_EXTRA_WIDTH: 100,
  COLUMN_MAX_WIDTH: 400,
  EXTRA_PADDING: 56,
  EXTRA_PADDING_FIRST_COLUMN: 72
};

const noRowsOverlay = (noResultsText) => <Stack data-testid='hyro-table-no-results' sx={{ position: "sticky", inset: 0, height: "100%", justifyContent: "center", alignItems: "center" }}>
  <img src={NothingHereYet} alt='' />
  <Typography mt={2} variant="body1" color="text.secondary">
    {noResultsText}
  </Typography>
</Stack>;

const footer = (showAddButton, onAddInlineRow, testId) => <>
  {showAddButton && <AddNewRowButton startIcon={<AddIcon />} onClick={onAddInlineRow} data-testid={`${testId}-add-new-row`}>Add New</AddNewRowButton>}
  <GridFooter>
    <GridFooterContainer>
      <GridPagination />
    </GridFooterContainer>
  </GridFooter>
</>;

// eslint-disable-next-line max-lines-per-function, @typescript-eslint/naming-convention
export default function HyroTable({
  columns = [],
  rows = [],
  rowActions,
  onRowClick,
  onRowUpdate,
  showPagination = false,
  noResultsText = DEFAULT_EMPTY_TEXT,
  disableHover = false,
  overrideMaxRowHeight = false,
  testId,
  onAddInlineRow,
  showInlineAddRow = false,
  minHeightAuto = false
}) {
  const containerRef = useRef(null);
  const prevRowCount = useRef(rows.length);
  const apiRef = useGridApiRef();
  const [isActionsSticky, setIsActionsSticky] = useState(false);
  const [currentEditableField, setCurrentEditableField] = useState(null);
  const { cellModesModel, handleCellClick, handleCellModesModelChange, setCellModesModel } = useSingleClickEdit();
  const [paginationModel, setPaginationModel] = useState(showPagination ? PAGINATION_INITIAL_STATE : {});
  const displayedRows = getDisplayedRows();
  let normalizedCols = normalizeColumnsWidth(columns);
  normalizedCols = addDefaultRenderCell(normalizedCols);
  const normalizedRows = rows.map((row, index) => ({ index, ...row }));
  const totalPages = Math.ceil(rows.length / paginationModel.pageSize);
  const isLastPage = paginationModel.page === totalPages - 1;
  const hasRowActions = Array.isArray(rowActions) || displayedRows.some(row => rowActions?.(row));
  const showAddButton = showInlineAddRow && (isLastPage || rows.length === 0);
  const editableColumns = columns.filter(column => column.editable);

  if (hasRowActions) {
    normalizedCols.push(...getActionsColumn());
  }

  useEffect(() => {
    const handleScrollPositionChange = (params) => {
      const gridElement = apiRef.current?.rootElementRef?.current?.querySelector(`.${gridClasses.virtualScroller}`);

      if (gridElement) {
        const maxScrollLeft = gridElement.scrollWidth - gridElement.clientWidth;
        const left = Math.ceil(params.left);

        setIsActionsSticky(left > 0 && left < maxScrollLeft);
      }
    };

    const unsubscribe = apiRef.current.subscribeEvent("scrollPositionChange", handleScrollPositionChange);

    return () => unsubscribe();
  }, [apiRef]);

  function getActionsColumn() {
    return [
      {
        field: "actions",
        headerName: "",
        width: 56,
        renderCell: ({ row, api }) => {
          const computeRowActions = Array.isArray(rowActions) ? rowActions : rowActions?.(row);
          if (!computeRowActions) return null;

          const rowIndex = api.getRowIndexRelativeToVisibleRows(row.id);

          return (
            <ThreeDotMenu
              testId={`${testId}-table-row-${rowIndex}`}
              menuItems={computeRowActions?.map(({ display, id, run }) => (<MenuItem
                key={id}
                data-testid={`${testId}-three-dot-menu-${display.replace(" ", "-")}`}
                onClick={() => run(row)}
              >
                {display}
              </MenuItem>))}
            />
          );
        }
      }];
  }

  function getDisplayedRows() {
    return paginationModel.pageSize > 0 && showPagination
      ? rows.slice(paginationModel.page * paginationModel.pageSize, paginationModel.page * paginationModel.pageSize + paginationModel.pageSize) : rows;
  }

  function normalizeColumnsWidth(columns) {
    return columns?.map(({ field, headerName, width: overrideWidth }, index) => {
      // find the longest content in the column, if it's an array, calculate the first element's length
      const longestColumnContent = displayedRows.map(row => {
        if (Array.isArray(row[field])) return row[field][0];
        return row[field];
      }).reduce((acc, curr) => {
        let width = getTextWidth({ text: curr, font: "600 14px Inter, sans-serif" });

        // if curr is an object then width should be the largest text width in the object
        if (typeof curr === "object" && curr !== null) {
          width = Object.values(curr).reduce((acc, curr) => {
            const currWidth = getTextWidth({ text: curr, font: "600 14px Inter, sans-serif", extraWidth: WIDTHS.EXTRA_PADDING });
            return currWidth > acc ? currWidth : acc;
          }, 0);
        }

        if (!Array.isArray(curr) && width > acc) return width;
        return acc;
      }, "");

      const isFieldAnArray = displayedRows.some(row => row[field] && Array.isArray(row[field]));
      const columnWidth = getTextWidth({
        text: headerName,
        extraWidth: WIDTHS.EXTRA_PADDING,
        font: "600 14px Inter, sans-serif"
      });

      const actualWidth = columnWidth > longestColumnContent ? columnWidth : longestColumnContent;
      let finalWidth;

      if (isFieldAnArray) {
        finalWidth = actualWidth + WIDTHS.ARRAY_EXTRA_WIDTH;
      } else {
        finalWidth = actualWidth + (index === 0 ? WIDTHS.EXTRA_PADDING_FIRST_COLUMN : WIDTHS.EXTRA_PADDING);
      }
      if (overrideWidth) finalWidth = overrideWidth;
      if (finalWidth > WIDTHS.COLUMN_MAX_WIDTH) finalWidth = WIDTHS.COLUMN_MAX_WIDTH;

      return {
        ...columns[index],
        minWidth: finalWidth,
        flex: overrideWidth ? 0 : 1
      };
    });
  }

  function addDefaultRenderCell(columns) {
    return columns.map((column, colIndex) => ({
      ...column,
      renderCell: (params) => (
        <StyledCellContainer data-testid={`row-${params.row.index}-table-cell-${colIndex}`} className="cell-container" isEditable={column.editable}>
          {column.renderCell ?
            column.renderCell(params) :
            <TextWrapper overrideMaxRowHeight={overrideMaxRowHeight}>{params.value}</TextWrapper>
          }
        </StyledCellContainer>
      )
    }));
  }

  function handleRowCountChange(rowCount) {
    const rowCountIncreasedByOne = prevRowCount.current + 1 === rowCount;

    if (rowCountIncreasedByOne) {
      const lastPage = Math.ceil(rows.length / paginationModel.pageSize);
      apiRef.current.setPage(lastPage);
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    prevRowCount.current = rowCount;
  }

  function handleRowUpdate(newRow, oldRow) {
    const hasCellChanged = currentEditableField && newRow[currentEditableField] !== oldRow?.[currentEditableField];
    const cellNewValue = newRow[currentEditableField];

    if (hasCellChanged) {
      onRowUpdate?.({ fieldName: currentEditableField, newValue: cellNewValue, newRow });
    }

    return newRow;
  }

  function handleCellKeyDown(params, event) {
    // Focus the next editable cell when tab is pressed
    if (event.key === "Tab") {
      event.preventDefault();

      if (Object.keys(cellModesModel).length === 0) return;

      const currentColumnIndex = editableColumns.findIndex(column => column.field === params.field);
      const nextEditableColumn = event.shiftKey ? editableColumns[currentColumnIndex - 1] : editableColumns[currentColumnIndex + 1];

      if (!nextEditableColumn) return;

      // We need the timeout to make sure the cell gets the edit mode
      setTimeout(() => {
        setCellModesModel({ [params.id]: { [nextEditableColumn.field]: { mode: GridCellModes.Edit } } });
      });
    }
  }

  return (
    <StyledContainer
      ref={containerRef}
      stickyActions={isActionsSticky}
      minHeightAuto={minHeightAuto}
      disableHover={disableHover}
      clickable={onRowClick}>
      <DataGrid
        apiRef={apiRef}
        rows={normalizedRows}
        columns={normalizedCols}
        onRowClick={onRowClick}
        disableColumnSorting
        disableColumnMenu
        disableVirtualization
        disableColumnResize
        disableRowSelectionOnClick
        hideFooter={!showPagination}
        columnHeaderHeight={48}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 57}
        hideFooterPagination={!showPagination}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        initialState={{
          pagination: { paginationModel: showPagination ? PAGINATION_INITIAL_STATE : {} }
        }}
        slots={{
          noRowsOverlay: () => noRowsOverlay(noResultsText),
          footer: () => footer(showAddButton, onAddInlineRow, testId)
        }}
        onRowCountChange={handleRowCountChange}
        onPaginationModelChange={setPaginationModel}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={(error) => console.error(error)}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        onCellEditStop={(params) => setCurrentEditableField(params.field)}
      />
    </StyledContainer>
  );
}
