import React from "react";

const StarIcon = ({ disabled }) => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clipPath="url(#clip0_2547_7630)">
    <path
      d="M10.0001 1.66663L11.6767 6.02586C11.9117 6.63689 12.0292 6.9424 12.212 7.19938C12.3739 7.42714 12.5729 7.62614 12.8007 7.78809C13.0576 7.97082 13.3632 8.08832 13.9742 8.32333L18.3334 9.99996L13.9742 11.6766C13.3632 11.9116 13.0576 12.0291 12.8007 12.2118C12.5729 12.3738 12.3739 12.5728 12.212 12.8005C12.0292 13.0575 11.9117 13.363 11.6767 13.9741L10.0001 18.3333L8.32345 13.9741C8.08844 13.363 7.97094 13.0575 7.78821 12.8005C7.62626 12.5728 7.42727 12.3738 7.19951 12.2118C6.94252 12.0291 6.63701 11.9116 6.02599 11.6766L1.66675 9.99996L6.02598 8.32333C6.63701 8.08832 6.94252 7.97082 7.1995 7.78809C7.42726 7.62614 7.62626 7.42714 7.78821 7.19938C7.97094 6.9424 8.08844 6.63689 8.32345 6.02586L10.0001 1.66663Z"
      stroke={disabled ? "#2D2C44" : "white"} strokeOpacity={disabled ? .24 : 1} strokeWidth="1.8" strokeLinecap="round"
      strokeLinejoin="round" />
  </g>
  <defs>
    <clipPath id="clip0_2547_7630">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>;

export default StarIcon;
