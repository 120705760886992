import React from "react";

import CallMeManager from "./CallMe/CallMeManager";
import WebSnippet from "./WebSnippet";
import useAppStore from "../../stores/appStore";
import { selectedAssistantSelector } from "../../stores/selectors/appSelectors";
import { ASSISTANT_CHANNELS } from "../../utils";

const PreviewButton = ({ isButton }) => {
  const selectedAssistant = useAppStore(selectedAssistantSelector);

  return <>
    {selectedAssistant?.channel === ASSISTANT_CHANNELS.PHONE ?
      <CallMeManager isButton={isButton} />
      : <WebSnippet isButton={isButton} />}
  </>;
};

export default PreviewButton;
