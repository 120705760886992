export const branchDeployerRowsSelector = state => state.branchDeployerRows;
export const branchesReleaseNamesSelector = state => state.branchDeployerRows?.map(branch => branch.releaseName);
export const getBranchDeployerRowsSelector = state => state.getBranchDeployerRows;
export const createBranchDeployerSelector = state => state.createBranchDeployer;
export const editBranchDeployerSelector = state => state.editBranchDeployer;
export const deleteBranchDeployerSelector = state => state.deleteBranchDeployer;
export const getBranchDeployerStatusSelector = state => state.getBranchDeployerStatus;
export const branchDeployerErrorSelector = state => state.branchDeployerError;
export const setBranchDeployerErrorSelector = state => state.setBranchDeployerError;
export const getDashboardSettingsSelector = state => state.getDashboardSettings;
