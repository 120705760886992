import { Typography, Popover } from "@mui/material";
import React from "react";

const HoverPopover = ({ title, description, hover, Handle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Handle
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        {...(hover
          ? {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose
          }
          : {
            onClick: handlePopoverOpen
          })}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        elevation={3}
        {...(!hover && {
          onClose: handlePopoverClose
        })}
        sx={{ pointerEvents: hover ? "none" : "auto" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{ style: { width: 327, padding: 16 } }}
      >
        {title
          ? (
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {title}
            </Typography>
          )
          : null}
        <Typography variant="body2">{description}</Typography>
      </Popover>
    </>
  );
};

export default HoverPopover;
