/* eslint-disable no-secrets/no-secrets */
/*
This is store was created to manage the Knowledge Editor page objects/rows from triplets

Entities are defined in triplets by the entity/fields relation:
[ "clinic","entity/fields","clinic_display,clinic_bot_phone_number,..."]

Based on each entity/fields value we can extract the field triplets that this field consists of:
[
  ["clinic_display", "field/label", "Clinic Name"],
  ["clinic_display", "field/type", "textfield"],
  ["clinic_display", "field/relation", "concept/display"]
  ["clinic_bot_phone_number", "field/label", "Bot Phone Number"],
  ["clinic_bot_phone_number", "field/type", "multipleValues"],
  ["clinic_bot_phone_number", "field/relation", "clinic/bot_phone_number"],
  ...
  ]

* Currently supported field/type relation are "radio" / "multipleValues" / "image" / "ref" / "phoneNumber" / "textfield"

Based on the entity field/relation triplets we extract the entities triplets
[
  ["3221b01d-4c13-4035-9557-c1935766371c_clinic","clinic/bot_phone_number","19704454335"],
  ["3221b01d-4c13-4035-9557-c1935766371c_clinic","clinic/bot_phone_number","19704454764"],
  ["3221b01d-4c13-4035-9557-c1935766371c_clinic","concept/display","Brighton Fam"],
  ...
]

Based on the extracted fields we are able to generate rows from triplets, note that some entities might have partial data/fields
a row generation by the example above will look as follows

{
   "id": "3221b01d-4c13-4035-9557-c1935766371c_clinic",  <---- as you see the id is the object value of all single entity triplets
   "clinic_display": "Brighton Fam"
   "clinic_bot_phone_number": ["19704454335", "19704454764"] <----- here you can see an example of multi value field being aggregated to a single prop
}

you can also look at kg_editor_api.js file under __mocks__ & kg_editor_api_mocks.js to learn more about how triplets are being manipulated to rows and the reversed process

*/

import { concat, omit, pipe, reject } from "ramda";

import {
  PUBLISH_STATUS_COLUMN,
  addIdsToRows,
  calcRefEntityOptions,
  formatMultipleValuesColumns,
  getAllEntities,
  getColumnIdToRelationMap,
  getColumnSpec,
  getEntityTripletsFromRows,
  getFieldsSpecByEntity,
  getRelationToColumnIdMap,
  FIELD_TYPES
} from "./utils";
import { PUBLISH_STATUS } from "../../pages/KnowledgeEditor/Table/utils";
import { deploymentService } from "../../services";
import { Relations, subjectEq, subjectIn } from "../../triplets";

// eslint-disable-next-line max-lines-per-function
const createKnowledgeEditorSlice = (set, get) => ({
  refEntityOptions: {},
  canCreatePhoneNumbers: null,
  entity: null,
  columnSpec: [],
  columns: [],
  relationToColumnIdMap: null,
  columnIdToRelationMap: null,

  setupEntity: async (entity) => {
    const { displayedTriplets } = get();

    const tripletFieldSpec = getFieldsSpecByEntity(entity)(displayedTriplets);
    const columnSpec = getColumnSpec(tripletFieldSpec);
    const columns = [...columnSpec, PUBLISH_STATUS_COLUMN];
    const relationToColumnIdMap = getRelationToColumnIdMap(tripletFieldSpec);
    const columnIdToRelationMap = getColumnIdToRelationMap(tripletFieldSpec);

    const refFieldsSpec = tripletFieldSpec.filter(
      spec => spec[Relations.FIELD_TYPE] === FIELD_TYPES.REF
    );
    const refEntityOptions = refFieldsSpec.reduce(
      (acc, currRefSpec) => ({
        ...acc,
        [currRefSpec[Relations.FIELD_ENTITY]]: calcRefEntityOptions(
          currRefSpec[Relations.FIELD_ENTITY],
          displayedTriplets
        )
      }),
      {}
    );

    const refEntityReversedOptions = refFieldsSpec.reduce(
      (acc, currRefSpec) => ({
        ...acc,
        [currRefSpec[Relations.FIELD_ENTITY]]: calcRefEntityOptions(
          currRefSpec[Relations.FIELD_ENTITY],
          displayedTriplets,
          true
        )
      }),
      {}
    );

    set({
      entity,
      columnSpec,
      refEntityOptions,
      refEntityReversedOptions,
      columns,
      relationToColumnIdMap,
      columnIdToRelationMap
    });

    // phone numbers
    const connectors = await deploymentService.findConnector({
      connectorType: "phoneConnector"
    });
    const twilioConfig = connectors[0]?.twilio;
    const canCreatePhoneNumbers
      = Boolean(twilioConfig)
      && Boolean(twilioConfig.appId)
      && Boolean(twilioConfig.subAccountId);

    set({ canCreatePhoneNumbers });
  },

  addEntityRows: async (rows) => {
    const { entity, columnIdToRelationMap, updateCurrentTriplets, columnSpec }
      = get();

    const formattedRows = formatMultipleValuesColumns(rows, columnSpec);
    const idRows = addIdsToRows(entity)(formattedRows);
    const entityTriplets = getEntityTripletsFromRows(
      entity,
      columnIdToRelationMap,
      idRows
    );

    const tripletsUpdateFn = concat(entityTriplets);
    await updateCurrentTriplets(tripletsUpdateFn);
  },

  updateEntityRow: async (row) => {
    const { entity, columnIdToRelationMap, updateCurrentTriplets } = get();

    const updatedRow = omit([PUBLISH_STATUS], row);
    const entityTriplets = getEntityTripletsFromRows(
      entity,
      columnIdToRelationMap,
      [updatedRow]
    );

    const tripletsUpdateFn = pipe(
      reject(subjectEq(row.id)),
      concat(entityTriplets)
    );
    await updateCurrentTriplets(tripletsUpdateFn);
  },

  deleteEntityRowsById: async (ids) => {
    const { updateCurrentTriplets } = get();

    const tripletsUpdateFn = reject(subjectIn(ids));
    await updateCurrentTriplets(tripletsUpdateFn);
  },

  replaceEntityRows: async (rows) => {
    const { entity, columnIdToRelationMap, updateCurrentTriplets, columnSpec }
      = get();

    const formattedRows = formatMultipleValuesColumns(rows, columnSpec);
    const idRows = addIdsToRows(entity)(formattedRows);
    const entityTriplets = getEntityTripletsFromRows(
      entity,
      columnIdToRelationMap,
      idRows
    );

    const { displayedTriplets } = get();
    const currentRowIds = getAllEntities(entity)(displayedTriplets);

    const tripletsUpdateFn = pipe(
      reject(subjectIn(currentRowIds)),
      concat(entityTriplets)
    );

    await updateCurrentTriplets(tripletsUpdateFn);
  }
});

export default createKnowledgeEditorSlice;
