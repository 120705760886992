import styled from "@emotion/styled";
import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

import { useIsMobile } from "../../hooks";
import { APP_HEADER_HEIGHT } from "../../layout/consts";

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "customBackgroundColor"
})(({ customBackgroundColor, theme }) => ({
  backgroundColor: customBackgroundColor ?? theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,
  position: "fixed",
  top: APP_HEADER_HEIGHT,
  zIndex: theme.zIndex.drawer + 1,
  width: "100%"
}));

const StyledStack = styled(Stack, {
  shouldForwardProp: prop => prop !== "isMobile" && prop !== "isReadOnly"
})`
  height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const StatusBar = ({ children, mobileChildren, customBackgroundColor, isReadOnly, headerRef }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  return (
    <Root data-testid="status-bar" id="status-bar" theme={theme} customBackgroundColor={customBackgroundColor}>
      <StyledStack
        ref={headerRef}
        isMobile={isMobile}
        isReadOnly={isReadOnly}
        gap={1.5}
        direction="row"
      >
        {isMobile
          ? (
            <Typography variant="subtitle2" sx={{ width: "100%" }}>
              {mobileChildren}
            </Typography>
          )
          : children
        }
      </StyledStack>
    </Root>
  );
};

export default StatusBar;
