import { put, post } from "src/services/api";

const fetchCollections = async (params) => {
  const url = `/assistants/collections/${params.selectedAssistantId}/${params.collectionName}/all`;
  return await post(url);
};

const fetchCollection = async (params) => {
  const url = `/assistants/collections/${params.selectedAssistantId}/${params.collectionName}/${params.collectionKey}/${params.collectionInstanceKey}`;
  return await post(url);
};

const updateCollection = async (params) => {
  const url = `/assistants/collections/${params.selectedAssistantId}/${params.collectionName}/${params.collectionKey}/${params.collectionInstanceKey}`;
  return await put(url, params.payload, { pageName: params.pageName });
};

const fetchCollectionRefs = async (params) => {
  const url = `/assistants/${params.selectedAssistantId}/collection-refs/${params.collectionId}`;
  return await post(url, params.payload, params.params);
};

export default {
  fetchCollections,
  fetchCollection,
  updateCollection,
  fetchCollectionRefs
};