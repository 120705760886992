export const FIRST_STEP_TITLE = "Ready to go live?";
export const FIRST_STEP_SUBTITLE
    = "Please note that the publishing process may take some time. During this processing period, further editing won't be possible. Are you certain you've completed your edits and are ready to publish?";
export const LABEL = "Add a description for your changes";
export const SECOND_STEP_SUBTITLE
    = "To help track your changes, please provide a description. This description will be associated with the version you're about to set live.";
export const SECOND_STEP_TITLE = "Name your changes";
export const FIRST_STEP_BUTTON_TEXT = "Continue";
export const SECOND_STEP_BUTTON_TEXT = "Set live";
export const DRAFT_ERRORS = {
  CONFLICT: {
    title: "Edit mode conflict",
    text: "It seems you're trying to enter edit mode, but another user is currently editing this assistant. To ensure a smooth experience, please refresh the page to see the most up-to-date status."
  },
  PUBLISH: {
    title: "Publishing error",
    text: "We encountered an error while attempting to publish your changes. Please refresh the page to resolve this issue."
  },
  UPDATE: {
    title: "System error notification",
    text: "A system error has occurred. Please refresh the page to resolve this issue."
  }
};

export const REFRESH_PAGE = "Refresh page";

export const POPOVERS = {
  BUILD_DRAFT: {
    title: "Building your assistant",
    body: "Your assistant is now being built. Once the process is complete, you will be notified and can then preview the assistant or set it live.",
    buttonText: "Got it"
  },
  DRAFT_EXPIRATION: {
    title: "Edit Mode Expiration Notice",
    body: "You are currently in edit mode, which will automatically log out after 2-days to ensure the security of your work. Please make sure to publish your work before the 2-days period expires.",
    buttonText: "Got it"
  },
  SET_LIVE: {
    title: "Your changes are safe and sound!",
    body: "The changes you made have been successfully submitted, and are on their way to go live. In the meantime, you can track them through this icon.",
    buttonText: "Got it"
  },
  UNASSIGNED: {
    title: "You have unconnected items",
    body: "Your assistant is now being built. Please note that some items are not assigned, so they won’t be active on the assistant:",
    buttonText: "Got it"
  }
};

export const DONT_SHOW_AGAIN_STORAGE = {
  UNASSIGNED: "unassigned",
  DRAFT_EXPIRATION: "draftExpiration",
  BUILD_DRAFT: "buildDraft",
  SET_LIVE: "setLive"
};
