import React from "react";
import { Typography } from "@mui/material";
import WelcomeIllustration from "./WelcomeIllustration";
import { styled } from "@mui/material/styles";
import { useFetchDashboardSettings } from "src/queries/hooks/settings";

const Title = styled(props => <Typography variant="h6" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.text.primary
  })
);

const Subtitle = styled(props => (
  <Typography variant="subtitle1" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.secondary
}));

const IllustrationContainer = styled("div")(({ theme }) => ({
  position: "relative",
  right: `-${theme.spacing(3)}`,
  flex: 1,
  [theme.breakpoints.up("xs")]: {
    display: "none"
  },
  [theme.breakpoints.up("md")]: {
    display: "flex"
  },
  justifyContent: "flex-end"
}));

const BannerContainer = styled("div")(({ theme, showBanner }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  height: "128px",
  marginTop: showBanner ? "34px" : 0
}));

const TextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5)
}));

const Banner = ({ firstName, accountName }) => {
  const dashboardSettings = useFetchDashboardSettings()?.data;

  return (
    <BannerContainer showBanner={dashboardSettings?.banner?.show}>
      <TextWrapper>
        {firstName
          ? (
            <Title>
              {"Hey, "}
              {firstName}
              !
            </Title>
          )
          : <Title>Hey!</Title>}
        <Subtitle>
          Get familiar with the
          {" "}
          {accountName}
          {" "}
          AI assistants, powered by Hyro.
        </Subtitle>
      </TextWrapper>
      <IllustrationContainer>
        <WelcomeIllustration />
      </IllustrationContainer>
    </BannerContainer>
  );
};

export default Banner;
