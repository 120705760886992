import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const AssistantIcon = () => (<SvgIcon>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 2C10.8954 2 10 2.89543 10 4C10 4.74028 10.4022 5.38663 11 5.73244V8H5C3.89543 8 3 8.89543 3 10V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V10C21 8.89543 20.1046 8 19 8H13V5.73244C13.5978 5.38663 14 4.74028 14 4C14 2.89543 13.1046 2 12 2ZM5 11C5 10.4477 5.44772 10 6 10H18C18.5523 10 19 10.4477 19 11V17C19 17.5523 18.5523 18 18 18H6C5.44772 18 5 17.5523 5 17V11ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15C9.55228 15 10 14.5523 10 14V13C10 12.4477 9.55228 12 9 12ZM15 12C14.4477 12 14 12.4477 14 13V14C14 14.5523 14.4477 15 15 15C15.5523 15 16 14.5523 16 14V13C16 12.4477 15.5523 12 15 12Z"
  />
</SvgIcon>);

export default AssistantIcon;
