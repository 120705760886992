import { useState } from "react";

import { useEffectAsync } from "../../../hooks";
import { CALL_ME_FIELDS } from "../../../phrasing";
import { get } from "../../../services/api";
import useAppStore from "../../../stores/appStore";
import { assistantIdSelector, systemModeSelector } from "../../../stores/selectors/appSelectors";
import { formatPhoneNumber, validatePhoneNumber } from "../../../utils";

const useCallMeDialog = () => {
  const systemMode = useAppStore(systemModeSelector);
  const previousSystemMode = useAppStore(systemModeSelector);
  const realSystemMode = systemMode === "preview" ? previousSystemMode : systemMode;
  const assistantId = useAppStore(assistantIdSelector);
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({ phoneNumber: "", callerId: "", site: "" });
  const disableCall = Object.values(errors)?.length > 0 || fields.phoneNumber === "";

  useEffectAsync(async () => {
    let sitesList;

    try {
      setLoading(true);
      sitesList = await get(`/assistants/collections/${assistantId}/call-me/sites?mode=${realSystemMode}`);

      if (sitesList?.length === 0) {
        sitesList = [{ key: "", display_key: "No options available.", disabled: true }];
      } else {
        sitesList = [{ key: "", display_key: "None" }, ...sitesList];
      }
      setSites(sitesList);
    } catch (e) {
      console.error(e);
      sitesList = [{ key: "", display_key: "Error loading sites. Please try again later.", disabled: true }];
      setSites(sitesList);
    }
    setLoading(false);
  }, []);

  // eslint-disable-next-line complexity
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    if (name === CALL_ME_FIELDS.PHONE_NUMBER.ID || name === CALL_ME_FIELDS.CALLER_ID.ID) {
      // camelCase to SNAKE_CASE
      const snakeCaseName = name.replace(/([A-Z])/g, "_$1").toUpperCase();
      const calleeValidation = validatePhoneNumber(value) || validatePhoneNumber(value, "IL");
      const callerIdValidation = !value || validatePhoneNumber(value);
      const validPhoneNumber = name === CALL_ME_FIELDS.PHONE_NUMBER.ID ? calleeValidation : callerIdValidation;

      if (validPhoneNumber) {
        delete newErrors[name];
      } else {
        newErrors = { ...newErrors, [name]: CALL_ME_FIELDS[snakeCaseName].ERROR };
      }
    }
    setFields({ ...fields, [name]: value && name !== CALL_ME_FIELDS.SITES.ID ? formatPhoneNumber(value) : value });
    setErrors(newErrors);
  };

  return { sites, handleChange, disableCall, errors, fields, loading };
};

export default useCallMeDialog;
