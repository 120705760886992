import { COLLECTION_PAGES, PAGE_PERMISSIONS, pathMatches, PORTAL_PAGES } from "./utils";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "./stores/appStore";
import {
  hasPageErrorTypeSelector,
  hasPermissionSelector,
  systemModeSelector
} from "./stores/selectors/appSelectors";
import { SYSTEM_MODES } from "./stores/slices/createAppSlice";
import { draftSelector } from "./stores/selectors/tripletsSelectors";
import { useAuth } from "@frontegg/react";
import { getErrorPageFromStatus } from "src/layout/ErrorPages/ErrorPage";
import { useFetchDashboardSettings } from "src/queries/hooks/settings";

const useEffectAsync = (f, deps) => {
  useEffect(() => {
    f();
    // eslint-disable-next-line
  }, deps);
};

const useIsAdminPanel = () => {
  const { pathname } = useLocation();
  const [isAdminPanel, setIsAdminPanel] = useState(pathname.includes("/admin"));

  useEffect(() => {
    setIsAdminPanel(pathname.includes("/admin"));
  }, [pathname]);

  return isAdminPanel;
};

const useIsCollection = () => {
  const [isCollectionPage, setIsCollectionPage] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsCollectionPage(pathMatches(COLLECTION_PAGES)(pathname));
  }, [pathname]);

  return isCollectionPage;
};

const useIsPortalPage = () => {
  const [isPortalPage, setIsPortalPage] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setIsPortalPage(pathMatches(PORTAL_PAGES)(pathname));
  }, [pathname]);
  return isPortalPage;
};

const useIsCurrentPageReadonly = () => {
  const hasPermission = useAppStore(hasPermissionSelector);
  const [isPageReadonly, setIsPageReadonly] = useState(false);
  const { pathname } = useLocation();
  const page = useCurrentAssistantPage();

  useEffect(() => {
    PAGE_PERMISSIONS[page]?.WRITE &&
    !hasPermission(PAGE_PERMISSIONS[page]?.WRITE) ?
      setIsPageReadonly(true) :
      setIsPageReadonly(false);
  }, [hasPermission, page, pathname]);

  return isPageReadonly;
};

export const useStatusBarDisplayed = () => {
  const isPortalPage = useIsPortalPage();
  const isPageReadonly = useIsCurrentPageReadonly();
  const draft = useAppStore(draftSelector);
  const systemMode = useAppStore(systemModeSelector);
  const dashboardSettings = useFetchDashboardSettings()?.data;

  return dashboardSettings?.banner?.show || (systemMode === SYSTEM_MODES.EDIT && ((isPortalPage && draft) || isPageReadonly));
};

const useCurrentAssistantPage = () => {
  const matchPage = useMatch("/:accountId/:assistantId/:page/*");
  return matchPage?.params?.page ?? "";
};

// only use for non admin pages
const useCurrentAccountId = () => {
  const matchPage = useMatch("/:accountId/:assistantId/:page/*");
  return matchPage?.params?.accountId ?? "";
};

const useCurrentAdminPage = () => {
  const matchPage = useMatch("/admin/*");
  return matchPage ? matchPage.params["*"] : null;
};

const useCurrentFullAssistantPage = () => {
  const matchPage = useMatch("/:accountId/:assistantId/*");
  return matchPage ? matchPage.params["*"] : null;
};

const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

const useAccessToken = () => {
  const { user } = useAuth();
  return user?.accessToken;
};

const useAsyncError = () => {
  // eslint-disable-next-line
  const [_, setError] = useState();
  return useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

const useErrorPage = () => {
  const pageErrorType = useAppStore(hasPageErrorTypeSelector);

  if (pageErrorType === null) {
    return null;
  }
  return getErrorPageFromStatus(pageErrorType);
};

export {
  useIsCollection,
  useAccessToken,
  useCurrentAdminPage,
  useCurrentAssistantPage,
  useCurrentFullAssistantPage,
  useCurrentAccountId,
  useEffectAsync,
  useIsAdminPanel,
  useIsPortalPage,
  useIsCurrentPageReadonly,
  useIsMobile,
  useAsyncError,
  useErrorPage
};
