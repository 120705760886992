import { styled } from "@mui/material/styles";

export const MandatoryBadge = styled("div")(({ close }) => ({
  width: "8px",
  height: "8px",
  backgroundColor: "#EF404B",
  borderRadius: "50%",
  ...(close && {
    position: "absolute",
    right: "0px",
    top: "0px"
  })
}));
