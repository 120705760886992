import { styled } from "@mui/material/styles";
import React from "react";

import { useIsMobile } from "../../hooks";

const IframeWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "homepage" && prop !== "isMobile"
})(({ homepage, isMobile }) => ({
  position: "relative",
  height: homepage ? isMobile ? "calc(100vh - 130px)" : "calc(100vh - 250px)" : "calc(100vh - 165px)"
}));
const InnerIframe = styled("iframe")(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: "100%",
  height: "100%"
}));

const ResponsiveIframe = ({ src, homepage }) => {
  const isMobile = useIsMobile();
  return (
    <IframeWrapper homepage={homepage} isMobile={isMobile}>
      <InnerIframe {...{ src, frameBorder: 0 }} data-testid={"looker-iframe"} />
    </IframeWrapper>
  );
};

export default ResponsiveIframe;
