import { Typography } from "@mui/material";
import React from "react";

import StatusBar from "../../../../../components/common/StatusBar";
import { StyledDivider } from "../PortalHeaderStyles";

const ReadOnlyHeader = () => (<StatusBar
  mobileChildren={<>Read only mode</>}
  isReadOnly
>
  <Typography variant="subtitle2">You only have view permissions for this page</Typography>
  <StyledDivider isReadOnly orientation="vertical"/>
  <Typography variant="subtitle2">Read only mode</Typography>
</StatusBar>);

export default ReadOnlyHeader;
