import { Typography, Link } from "@mui/material";
import React from "react";

import StatusBar from "./StatusBar";
import { COLORS } from "../admin/Settings/consts";

const DashboardSettingsBanner = ({ dashboardSettings }) => {
  const showStatusPage = dashboardSettings?.banner?.color === COLORS.INCIDENT;
  return <StatusBar
    customBackgroundColor={dashboardSettings?.banner?.color}>
    <Typography variant="subtitle2">{dashboardSettings?.banner?.customText || dashboardSettings?.banner?.text}{showStatusPage ? <>
      <span>&nbsp;For more details, please visit our&nbsp;</span>
      <Link
        href={"https://status.hyro.ai"} target="_blank" sx={{ color: "white", textDecorationColor: "white" }}>Status
        page</Link>
    </> : null}{showStatusPage ? ".":""}</Typography>
  </StatusBar>;
};

export default DashboardSettingsBanner;
