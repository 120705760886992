import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import HyroLogo from "./HyroLogo";
import hyroLogo from "../../static/images/logo.svg";
import { ADMIN } from "../../utils";

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  marginLeft: "10px",
  color: theme.palette.primary.main,
  letterSpacing: "0.16px",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  minWidth: "45px",
  height: "20px",
  padding: "12px 7px",
  alignSelf: "center",
  ".MuiChip-label": {
    padding: "1px 2px"
  }
}));

const AdminHyroLogo = () => (<>
  <HyroLogo redirectTo={`/${ADMIN}`} src={hyroLogo} />
  <StyledChip label="Admin" />
</>);

export default AdminHyroLogo;
