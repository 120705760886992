import { Button } from "@hyro/ui";
import { Checkbox, FormControlLabel, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

const InfoPopover = ({ anchorRef, open, onGotIt, data, slots, enableCheckbox = true }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleGotItClick = () => {
    onGotIt?.(checked);
  };

  return open
    ? (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{ mt: "12px" }}
        PaperProps={{
          sx: {
            overflow: "visible",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 12,
              width: 10,
              height: 10,
              backgroundColor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
              boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)"
            }
          }
        }}
        anchorEl={anchorRef.current}
        open={open}
      >
        <Stack sx={{ p: 2, width: 328 }} gap={2}>
          <Stack gap="4px">
            <Typography fontSize={16} fontWeight={500}>
              {data.title}
            </Typography>
            {slots?.body ? slots?.body : <Typography fontSize={14}>
              {data.body}
            </Typography>}
          </Stack>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: 295
            }}
          >
            {enableCheckbox ? <FormControlLabel
              componentsProps={{ typography: { fontSize: "14px" } }}
              control={
                <Checkbox checked={checked} onChange={handleCheckboxChange} />
              }
              label={"Don't show again"}
            /> : null}

            <Button
              sx={{ ml: "auto" }}
              data-testid={"draft-expiration-submit-button"}
              variant="contained"
              color="primary"
              onClick={handleGotItClick}
              size="small"
            >
              {data.buttonText}
            </Button>
          </div>
        </Stack>
      </Popover>
    )
    : null;
};

export default InfoPopover;
