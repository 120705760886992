import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { APP_HEADER_HEIGHT, APP_MOBILE_HEADER_HEIGHT, APP_STATUS_BAR_HEIGHT } from "./consts";

const AppWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "statusBarDisplayed" && prop !== "errorPage"
})(({ theme, statusBarDisplayed, errorPage }) => ({
  width: "100%",
  boxSizing: "border-box",
  position: "relative",
  padding: `${statusBarDisplayed ? APP_STATUS_BAR_HEIGHT + 24 : 24}px 24px 24px 24px`,

  // we double the header height in error because there will be an extra AppWrapper above the error page
  [theme.breakpoints.down("sm")]: {
    height:  `calc(100vh - ${errorPage ? APP_MOBILE_HEADER_HEIGHT*2 : APP_MOBILE_HEADER_HEIGHT}px)`,
    marginTop: `${errorPage ? "" : APP_MOBILE_HEADER_HEIGHT + "px"}`
  },
  [theme.breakpoints.up("sm")]: {
    height: `calc(100vh - ${errorPage ? APP_HEADER_HEIGHT*2 : APP_HEADER_HEIGHT}px)`,
    marginTop: `${errorPage ? "" : APP_HEADER_HEIGHT + "px"}`
  },
  overflowY: "scroll"
}));

const ContentWrapper = styled(Box)(() => ({
  maxWidth: 1200,
  margin: "auto"
}));

const TopContainer = styled("div")(({ theme, showBreadcrumbs }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(),
  marginBottom:  theme.spacing(showBreadcrumbs ? 2 : 3)
}));

export { AppWrapper, ContentWrapper, TopContainer };
