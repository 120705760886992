import { drawerClasses } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import React from "react";

import DrawerContent from "./DrawerContent";
import { useIsMobile } from "../../hooks";
import useAppStore from "../../stores/appStore";
import { systemModeSelector } from "../../stores/selectors/appSelectors";
import { SYSTEM_MODES } from "../../stores/slices/createAppSlice";

const DRAWER_WIDTH = 240;

const openedMixin = theme => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

const closedMixin = theme => ({
  width: `calc(${theme.spacing(9)} + 1px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  [theme.breakpoints.down("sm")]: {
    width: 0,
    overflowX: "hidden"
  }
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ".MuiDrawer-paper": {
    overflowY: "visible"
  },

  ...(open
    ? {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme)
    }
    : {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme)
    })
}));

const Navbar = ({ open, setOpen, ...props }) => {
  const isMobile = useIsMobile();
  const systemMode = useAppStore(systemModeSelector);

  return (
    <>
      {isMobile ?
        <MuiDrawer
          open={open}
          onClose={() => setOpen(false)}
          ModalProps={{ keepMounted: true }}
          sx={{
            ...(systemMode === SYSTEM_MODES.PREVIEW ? {
              [`.${drawerClasses.paper}`]: {
                border: "unset"
              }
            } : {}),
            "display": { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              width: DRAWER_WIDTH
            }
          }}>
          <DrawerContent
            open={open}
            setOpen={setOpen}
            {...props} />
        </MuiDrawer>
        :
        <Drawer
          open={open}
          variant={"permanent"}
          sx={{
            ...(systemMode === SYSTEM_MODES.PREVIEW ? {
              [`.${drawerClasses.paper}`]: {
                border: "unset"
              }
            } : {}),
            display: { xs: "none", sm: "block" }
          }}
        >
          <DrawerContent
            open={open}
            setOpen={setOpen}
            {...props}
          />
        </Drawer>}
    </>
  );
};

export default Navbar;
