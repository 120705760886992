import React from "react";

import HyroLogo from "./HyroLogo";
import hyroLogo from "../../static/images/logo.svg";
import useAppStore from "../../stores/appStore";
import { selectedAccountIdSelector } from "../../stores/selectors/appSelectors";

const DashboardHyroLogo = () => {
  const accountId = useAppStore(selectedAccountIdSelector);
  return (
    <HyroLogo redirectTo={accountId ? `/${accountId}` : "/"} src={hyroLogo} />
  );
};

export default DashboardHyroLogo;
