import { LoadingButton } from "@mui/lab";
import { buttonClasses, Divider, IconButton } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

import { APP_HEADER_HEIGHT, APP_MOBILE_HEADER_HEIGHT, COLORS } from "../consts";

export const Header = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "showHeaderSkeleton" && prop !== "editBackground"
})(({ theme, showHeaderSkeleton, editBackground }) => ({
  display: showHeaderSkeleton ? "none" : "flex",
  position: "fixed",
  boxShadow: "none",
  backgroundColor: editBackground ?? "#FFFFFF",
  borderBottom: "1px solid rgba(45, 44, 68, 0.12)",
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down("sm")]: {
    height: APP_MOBILE_HEADER_HEIGHT,
    padding: "6px 16px",
    justifyContent: "center"
  },
  [theme.breakpoints.up("sm")]: {
    height: APP_HEADER_HEIGHT,
    padding: "14px 24px"
  },
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export const StyledDivider = styled(Divider)(() => ({
  marginLeft: "12px",
  height: "100%"
}));

export const StyledButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== "extraPadding"
})`
  height: 36px;
  line-height: 20px;
  margin-right: 4px;
  padding: 8px ${({ extraPadding }) => extraPadding ? "20px" : "12px"};

  box-shadow: unset;
  ${({ variant }) => variant === "outlined" && "background-color: #ffffff;"}
  ${({ variant }) => variant === "outlined" && `border: 1px solid ${COLORS.BTN_BORDER_COLOR};`}
  ${({ variant, disabled }) => variant === "contained" && !disabled && `
  outline: 1px solid ${COLORS.BTN_BORDER_COLOR};
  outline-offset: -1px;
  `}

  &:disabled {
    ${({ variant, disabled }) => variant === "contained" && !disabled && `outline: 1px solid ${COLORS.DISABLED_BTN_BORDER_COLOR};`}
    background-color: #ffffff;
  }

  &:hover {
    box-shadow: unset;
    background-color: ${({ variant, theme }) => variant === "outlined" ? COLORS.OUTLINED_HOVER_BTN_BG : theme.palette.secondary.dark};
    ${({ variant }) => variant === "outlined" && `outline: 1px solid ${COLORS.BTN_BORDER_COLOR}; outline-offset: -1px;`}
  }

  .${buttonClasses.startIcon} {
    margin-left: 0;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 6px;

  &:hover {
    background-color: ${COLORS.ICON_BTN_HOVER_BG};
  }
`;
