import React from "react";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";

import ErrorIcon from "../../icons/ErrorIcon";
import { DIALOG_VARIANTS } from "../common/Dialogs/DialogConsts";

const ErrorDialog = ({ isOpen, onSubmit }) => (
  <Dialog
    open={isOpen}
    title="Something went wrong"
    size="medium"
    variant={DIALOG_VARIANTS.acknowledgement}
    align="center"
    submitButtonText="Got it"
    handleSubmit={onSubmit}
  >
    <div
      style={{ textAlign: "center", paddingTop: "4vh", paddingBottom: "4vh" }}
    >
      <ErrorIcon />
      <div>An unexpected error has occurred.</div>
      <div>Please try again later. Contact support if the error persists.</div>
    </div>
  </Dialog>
);

export default ErrorDialog;
