import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { notifyPageNav } from "../../analytics/notifyCustomActions";
import { useIsMobile } from "../../hooks";

const HyroLogo = ({ redirectTo, src }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    notifyPageNav("hyroLogo", "logo");
    navigate(redirectTo, { replace: true });
  };
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
      }}
      onClick={handleClick}
    >
      <img
        style={{ height: isMobile ? 24 : 36, width: isMobile ? 62 : 82 }}
        src={src}
        alt="logo"
      />
    </Box>
  );
};

export default HyroLogo;
