import React from "react";
import { useLocation } from "react-router-dom";

import Breadcrumbs from "./Breadcrumbs";
import { useIsMobile } from "../../hooks";
import { breadcrumbAllowedPages } from "../../utils";
import { TopContainer } from "../LayoutWrappers";
import MobileAssistantNavbar from "../Navigation/AssistantNavigation/MobileAssistantNavbar";
import PageTitle from "../PageTitle";

const AssistantHeader = props => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const showBreadcrumbs = breadcrumbAllowedPages.some(page => location?.pathname.includes(page));
  return (
    <TopContainer showBreadcrumbs={showBreadcrumbs} statusBarDisplayed={props?.showStatusBar}>
      {isMobile && <MobileAssistantNavbar {...props} />}
      {showBreadcrumbs ? <Breadcrumbs /> : <PageTitle />}
    </TopContainer>
  );
};

export default AssistantHeader;
