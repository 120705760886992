import { filter, map, pipe } from "ramda";

import {
  getEntityRowsFromTriplets,
  getRowsWithPublishStatus,
  publishStatusComparator
} from "./utils";
import { Relations, relationEq, subject } from "../../triplets";

export const knowledgeEntityTypesSelector = state =>
  pipe(
    filter(relationEq(Relations.ENTITY_FIELDS)),
    map(subject)
  )(state.displayedTriplets);

export const getColumnsByEntitySelector = state => [
  state.columns,
  state.columnSpec
];

export const addEntityRowsSelector = state => state.addEntityRows;

export const updateEntityRowSelector = state => state.updateEntityRow;

export const deleteEntityRowsByIdsSelector = state =>
  state.deleteEntityRowsById;

export const refEntityOptionsSelector = state => state.refEntityOptions;

export const validateRefValueByEntitySelector = state => (entity, value) =>
  Object.values(state.refEntityOptions[entity]).includes(value);

export const validateRefKeyByEntitySelector = state => (entity, key) =>
  Object.keys(state.refEntityOptions[entity]).includes(key);

export const refEntityReversedOptionsSelector = state =>
  state.refEntityReversedOptions;

export const canCreatePhoneNumbersSelector = state =>
  state.canCreatePhoneNumbers;

export const replaceEntityRowsSelector = state => state.replaceEntityRows;

export const setupEntitySelector = state => state.setupEntity;

export const rowsSelector = (state) => {
  const {
    displayedTriplets,
    originalTriplets,
    entity,
    columnSpec,
    relationToColumnIdMap,
    draft
  } = state;
  const currentRows = getEntityRowsFromTriplets(
    displayedTriplets,
    entity,
    columnSpec,
    relationToColumnIdMap
  );
  const originalRows = getEntityRowsFromTriplets(
    originalTriplets,
    entity,
    columnSpec,
    relationToColumnIdMap
  );
  const currentRowsWithStatus = getRowsWithPublishStatus(
    currentRows,
    originalRows,
    draft
  );
  const sortedRows = currentRowsWithStatus.sort(publishStatusComparator);
  return sortedRows;
};
