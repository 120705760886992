import { MenuItem } from "@mui/material";
import React from "react";

import ColumnCell from "./ColumnCell";
import ThreeDotMenu from "./ThreeDotMenu";
import { FIELD_TYPES } from "../../../stores/KnowledgeEditor/utils";

const commaSeperatedRegex
  = /^[a-zA-Z0-9 %!@#$&()\\-`.+,/_^*]+((,)[a-zA-Z0-9 %!@#$&()\\-`.+,/_^*]+)*[a-zA-Z0-9%!@#$&()\\-`.+,/_^*]+$/;

const PUBLISH_STATUS = "publishStatus";
const PUBLISH_STATUSES = {
  NEW: "New",
  EDITED: "Edited",
  LIVE: "Live"
};

const getTableColumns = (
  columns,
  updateRow,
  isReadonly,
  onClickEdit,
  onClickDelete
) => {
  const width = `${100 / (columns.length + 1)}%`;

  let tableColumns = columns.map(column => ({
    id: column.id,
    label: column.label,
    width,
    renderCell: row => (
      <ColumnCell row={row} updateRow={updateRow} columnSpec={column} />
    )
  }));

  const editMenu = isReadonly
    ? {}
    : {
      id: "three_dot_actions",
      width: "4%",
      renderCell: row => (
        <ThreeDotMenu
          stopPropagation
          menuItems={[
            <MenuItem
              key="edit"
              onClick={(e) => onClickEdit(e, row)}
            >
                Edit
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => onClickDelete(row)}
            >
                Delete
            </MenuItem>
          ]}
        />
      )
    };

  tableColumns = [...tableColumns, editMenu];

  return tableColumns;
};

const createSampleForCsv = columnSpec =>
  columnSpec.reduce(
    (acc, { label, columnType }) => ({
      ...acc,
      [label]: getSampleData(columnType)
    }),
    {}
  );

const createSampleRow = columnSpec =>
  columnSpec.reduce(
    (acc, { id, columnType }) => ({
      ...acc,
      [id]: getSampleData(columnType)
    }),
    {}
  );

const getSampleData = (columnType) => {
  if (columnType === FIELD_TYPES.MULTIPLE_VALUES) {
    return [];
  }
  return "";
};

// this function is responsible for validating values of entity fields by type, note that the default is not specific due to some fields being 'text' as others 'textfield'
// eslint-disable-next-line complexity
const validateFieldByFieldType = ({
  value,
  columnType,
  validateFunction,
  radioOptions = null,
  refEntity = null
}) => {
  switch (columnType) {
  case FIELD_TYPES.MULTIPLE_VALUES:
    return commaSeperatedRegex.test(value);
  case FIELD_TYPES.IMAGE:
    return true;
  case FIELD_TYPES.RADIO:
    return radioOptions.includes(value);
  case FIELD_TYPES.REF:
    return validateFunction(refEntity, value);
  case FIELD_TYPES.PHONE_NUMBER:
    return value && value.length <= 11;

  default:
    return typeof value === "string" || value instanceof String;
  }
};

export {
  PUBLISH_STATUS,
  PUBLISH_STATUSES,
  getTableColumns,
  createSampleRow,
  createSampleForCsv,
  validateFieldByFieldType
};
