import styled from "@emotion/styled";
import {
  Autocomplete,
  autocompleteClasses,
  Box, buttonBaseClasses, buttonClasses, Chip,
  inputBaseClasses,
  outlinedInputClasses,
  selectClasses,
  TextField, Typography
} from "@mui/material";
import React from "react";

export const CollectionsGrid = ({ children }) => (<Box
  sx={{
    display: "grid",
    gridTemplateColumns: {
      lg: "repeat(3, 1fr)",
      md: "repeat(2, 1fr)"
    }
  }}
  gap={3}
>{children}</Box>);

export const StyledTextField = styled(TextField, {
  // eslint-disable-next-line complexity
  shouldForwardProp: prop => prop !== "isFieldReadOnlyFromSpec"
    && prop !== "isMultiLine"
    && prop !== "fieldType"
    && prop !== "onAddButtonClick"
    && prop !== "shouldShowAddButton"
    && prop !== "currentSelectedLanguage"
    && prop !== "shouldShowLanguageFlag"
    && prop !== "fetchingRefs"
    && prop !== "popperErrorPlacement"
    && prop !== "showErrorInPopper"
    && prop !== "showAllChips"
})`
  .${outlinedInputClasses.root} {
    border-radius: 8px;
    background: ${({ isFieldReadOnlyFromSpec, theme }) => isFieldReadOnlyFromSpec ? "#F5F5F5" : theme.palette.common.white};
    line-height: 24px;

    ${({ isFieldReadOnlyFromSpec }) => isFieldReadOnlyFromSpec && `
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
      }
    `}
    ${({ readOnly }) => readOnly && `
      & .${autocompleteClasses.endAdornment} {
        pointer-events: none;
      }
    `}
    & fieldset {
      border: 1px solid rgba(45, 44, 68, 0.24);
      ${({ isFieldReadOnlyFromSpec }) => isFieldReadOnlyFromSpec && "border: none;"}
    }

    &.${inputBaseClasses.root}.Mui-disabled textarea,
    &.${inputBaseClasses.root}.Mui-disabled input {
      color: #363554;
      -webkit-text-fill-color: unset;
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    `
    &:hover .${inputBaseClasses.root} .${outlinedInputClasses.notchedOutline},
    .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
      border-color: rgba(0, 0, 0, 0.23);
      border-width: 1px;
    }

    .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input} {
      cursor: default;
    }
    .${selectClasses.iconOutlined} {
      opacity: .24;
    }
    & .${autocompleteClasses.endAdornment} .${buttonClasses.root} {
      pointer-events: none;
    }
  `}
`;

export const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== "isFieldReadOnlyFromSpec"
})`
  ${({ readOnly }) => readOnly && `
  & .${autocompleteClasses.endAdornment} .${buttonBaseClasses.root} {
    opacity: 0.24;
  }

  .${inputBaseClasses.root} {
    min-height: 56px;
  }

  & .${autocompleteClasses.clearIndicator} {
    display: none;
  }

  &:hover
    .${inputBaseClasses.root}
    .${outlinedInputClasses.notchedOutline},
    .${inputBaseClasses.root}.${outlinedInputClasses.focused}
    .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }
  `}
  ${({ isFieldReadOnlyFromSpec }) => isFieldReadOnlyFromSpec &&
    `
    & .${inputBaseClasses.root} {
      background: #F5F5F5;
       cursor: not-allowed ;
      &:hover {
        cursor: not-allowed;
      }
      & fieldset {
        border: none;
      }
    }

    & .${inputBaseClasses.input} {
      cursor: not-allowed;
    }

    & .${autocompleteClasses.endAdornment} .${buttonBaseClasses.root} {
    opacity: 0.24;
    cursor: not-allowed ;
  }
  `}
`;

export const StyledChip = styled(Chip)`
  font-size: 12px;
  &.Mui-disabled {
    opacity: 1;
  }
`;

export const EllipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

