import {
  always,
  equals,
  evolve,
  filter,
  head,
  ifElse,
  length,
  mergeRight,
  nth,
  omit,
  pipe,
  reduce,
  split,
  values
} from "ramda";

import {
  DRAFT_STATUS,
  RESULT_CARD_CONFIG_RELATIONS,
  Relations,
  object,
  relation,
  relationIn,
  subject,
  tripletsToObjects
} from "../../triplets";
import { DRAFT_STATES, SYNONYM } from "../../utils";

export const handleDraftTripletsSelector = state => [
  state.publishCurrentTripletsToDraft,
  state.deleteCurrentDraft
];
export const deletePublishedDraftSelector = state =>
  state.deletePublishedDraft;
export const updateCurrentTripletsSelector = state =>
  state.updateCurrentTriplets;
export const isRefreshingTripletsSelector = state =>
  state.tripletsStatus === DRAFT_STATUS.LOADING
    || state.tripletsStatus === DRAFT_STATUS.PUBLISHING;
export const isSavingTripletsSelector = state =>
  state.tripletsStatus === DRAFT_STATUS.SAVING;
export const isPublishingTripletsSelector = state =>
  state.tripletsStatus === DRAFT_STATUS.PUBLISHING;
export const isTripletsIdleSelector = state =>
  state.tripletsStatus === DRAFT_STATUS.IDLE;
export const draftSelector = state => state.draft;
export const isEditModeSelector = state =>
  state.draft?.status === DRAFT_STATES.EDIT;
export const failedToCreateDraftSelector = state => state.failedToCreateDraft;
export const setFailedToCreateDraftSelector = state =>
  state.setFailedToCreateDraftSelector;
export const failedToPublishDraftSelector = state =>
  state.failedToPublishDraft;
export const setFailedToPublishDraftSelector = state =>
  state.setFailedToPublishDraftSelector;
export const infoPopoversSelector = state => state.infoPopovers;
export const setInfoPopoversSelector = state =>
  state.setInfoPopovers;
export const couldNotLoadTripletsSelector = state =>
  state.couldNotLoadTriplets;
export const setCouldNotLoadTripletsSelector = state =>
  state.setCouldNotLoadTriplets;
export const currentTripletsSelector = state => state.displayedTriplets;
export const originalTripletsSelector = state => state.originalTriplets;
export const updateResultCardConfigSelector = state =>
  state.updateResultCardConfig;

const RESULT_CARD_UI_FORMAT_FNS = {
  [Relations.RENDER_TITLE]: pipe(
    split(","),
    ifElse(pipe(length, equals(1)), always(""), nth(1))
  )
};

const RESULT_CARD_UI_DEFAULTS = reduce(
  (defaults, rel) => ({ ...defaults, [rel]: "" }),
  {}
)(values(RESULT_CARD_CONFIG_RELATIONS));

const isResultCardConfigTriplet = relationIn(
  values(RESULT_CARD_CONFIG_RELATIONS)
);

export const resultCardConfigSelector = state =>
  pipe(
    filter(isResultCardConfigTriplet),
    pipe(tripletsToObjects, head),
    omit(["id"]),
    evolve(RESULT_CARD_UI_FORMAT_FNS),
    mergeRight(RESULT_CARD_UI_DEFAULTS)
  )(state.displayedTriplets);

export const synonymsSelector = state => [
  state.displayedTriplets.reduce(
    (acc, triplet) =>
      relation(triplet) === SYNONYM
        ? [...acc, { trigger: subject(triplet), synonym: object(triplet) }]
        : acc,
    []
  ),
  state.updateSynonyms
];

export const isTriggerInGraphSelector = state => state.isTriggerInGraph;
export const isFirstDraftSelector = state => state.draft?.isFirstDraft;

export const createNewDataSourceSelector = state => state.createNewDataSource;
export const updateDataSourceSelector = state => state.updateExistingDataSource;
export const deleteDataSourceSelector = state => state.deleteDataSourceFile;
