import React from "react";

import ErrorPage from "./ErrorPage";
import ForbiddenIcon from "./ForbiddenIcon";

const ForbiddenErrorPage = () => {
  const pageLinks = [
    { url: "/", text: "Go to home page" },
    { url: "mailto:support@hyro.ai", text: "Contact support" }
  ];

  return (
    <ErrorPage
      code="403"
      message="Forbidden"
      explanation="You don’t have access to this page"
      links={pageLinks}
      Icon={ForbiddenIcon}
    />
  );
};

export default ForbiddenErrorPage;
