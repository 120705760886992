import { Stack, Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getErrorPageFromStatus } from "src/layout/ErrorPages/ErrorPage";
import { DOWNLOAD_BTN_VARIANTS } from "src/utils";

import { COLUMNS } from "./consts";
import { HyroHeader, HyroLoader, HyroTable } from "../../components/hyro-components";
import DownloadButton from "../../components/hyro-components/DownloadButton";
import DateTimeContext from "../../contexts/DateTimeContext";
import { useEffectAsync } from "../../hooks";
import { get } from "../../services/api";
import useAppStore from "../../stores/appStore";
import { assistantIdSelector } from "../../stores/selectors/appSelectors";
import { capitalize } from "../../utils";

const searchColumns = ["timestamp", "pageName", "actionName", "userEmail", "statusCode"];

const AuditLog = () => {
  let ErrorPage;
  const location = useLocation();
  const { formattedMomentTimezone } = useContext(DateTimeContext);
  const [filteredLogs, setFilteredLogs] = useState({ rows: null, count: 0 });
  const [logs, setLogs] = useState({ rows: null, count: 0 });
  const [error, setError] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selectedAssistantId = useAppStore(assistantIdSelector);

  useEffectAsync(async () => {
    try {
      const isAdmin = location.pathname.includes("admin");
      const url = isAdmin ? "/admin/assistant/audit-log" : `/assistants/${selectedAssistantId}/audit-log`;
      const logs = await get(url);
      setFilteredLogs(logs ?? { rows: [], count: 0 });
      setLogs(logs ?? { rows: [], count: 0 });
    } catch (e) {
      console.error(e);
      ErrorPage = getErrorPageFromStatus(e.response.status);
      setError(true);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!searchText) return setFilteredLogs(logs);
    const filteredLogsTemp = logs?.rows?.filter(log => searchColumns.some(column => log[column]?.toString().toLowerCase().includes(searchText.toLowerCase())));
    setFilteredLogs({ ...filteredLogs, rows: filteredLogsTemp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  if (error) return <ErrorPage />;
  if (!logs?.rows) return <HyroLoader />;

  return <>
    <Stack direction='row' alignItems='center' gap={1.2}>
      <HyroHeader
        title={""}
        handleSearch={setSearchText}
      />
      <Box sx={{ mb: 2 }}>
        <DownloadButton
          variant={DOWNLOAD_BTN_VARIANTS.OUTLINED}
          fileName={"audit-log"}
          fileData={filteredLogs?.rows.map((row) => ({ ...row, timestamp: formattedMomentTimezone(row.timestamp) })) ?? []}
          fileHeaders={[
            { key: COLUMNS.timestamp.id, label: COLUMNS.timestamp.label },
            { key: COLUMNS.pageName.id, label: COLUMNS.pageName.label },
            { key: COLUMNS.actionName.id, label: COLUMNS.actionName.label },
            { key: COLUMNS.userEmail.id, label: COLUMNS.userEmail.label },
            { key: COLUMNS.statusCode.id, label: COLUMNS.statusCode.label }
          ]}
        />
      </Box>
    </Stack>
    <HyroTable
      alwaysShowActionsColumn
      showPagination={logs?.rows?.length >= 10}
      rows={filteredLogs?.rows}
      columns={[
        {
          field: COLUMNS.timestamp.id, headerName: COLUMNS.timestamp.label, renderCell: ({ value }) =>
            formattedMomentTimezone(value)
        },
        {
          field: COLUMNS.pageName.id,
          headerName: COLUMNS.pageName.label,
          renderCell: ({ value }) => capitalize((value ?? "").replace("-", " "))
        },
        { field: COLUMNS.actionName.id, headerName: COLUMNS.actionName.label },
        { field: COLUMNS.userEmail.id, headerName: COLUMNS.userEmail.label },
        { field: COLUMNS.statusCode.id, headerName: COLUMNS.statusCode.label }
      ]}/>
  </>;
};

export default AuditLog;
