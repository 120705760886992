import { TabContext, TabPanel } from "@mui/lab";
import { Paper, Tab, Tabs, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";

import { middleEllipsisTrim } from "../../utils";
import { MandatoryBadge } from "../common/MandatoryBadge";

const TabsBox = styled(Box)(({ borderColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: `1px solid ${borderColor}`
}));

const HyroTabs = ({
  headerComponent,
  tabsSpec,
  trimLabel = false,
  borderColor = "#b5b5b5",
  tabsSideComponent = null,
  selectedValue,
  customTabHeight,
  onTabChange,
  panelStyle }) => {
  const [activeTab, setActiveTab] = useState(tabsSpec[0].value);

  useEffect(() => {
    let { value } = tabsSpec[0] || {};

    if (selectedValue) {
      value = tabsSpec.find(({ value }) => value?.toLowerCase() === selectedValue?.toLowerCase())?.value;
      value = value ?? tabsSpec[0].value;
    }
    setActiveTab(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
    onTabChange?.(newActiveTab);
  };

  return (
    <>
      {headerComponent}
      <TabContext value={activeTab}>
        <TabsBox borderColor={borderColor}>
          <Tabs
            sx={{ height: "100%" }}
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabsSpec.map(({ label, value, disabled, mandatory }) => (
              <Tab
                data-testid={`hyro-tab-${label}`}
                label={
                  trimLabel
                    ? (
                      <Tooltip title={label} enterDelay={100} placement="top">
                        <span>{middleEllipsisTrim(label, 10)}</span>
                      </Tooltip>
                    )
                    : (<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <span>{label}</span>
                      {mandatory && <MandatoryBadge />}
                    </div>)
                }
                value={value}
                key={value}
                disabled={disabled}
                style={{ minHeight: customTabHeight }}
              />
            ))}
          </Tabs>
          {tabsSideComponent}
        </TabsBox>
        {tabsSpec.map(({ content, label, value }) => (
          activeTab === value ? <TabPanel value={value} key={value} style={{ height: "100%", ...panelStyle }}>
            {content ?? (
              <Paper>
                {label}
                {" "}
                {content}
              </Paper>
            )}
          </TabPanel> : null
        ))}
      </TabContext>
    </>
  );
};

export default HyroTabs;
