import { useQuery, useQueries, useMutation } from "@tanstack/react-query";
import useAppStore from "src/stores/appStore";
import { useEffect } from "react";
import { FETCH_QUERY_KEYS } from "src/queries/consts";
import collectionsService from "src/queries/services/collections";

const NUMBER_OF_RETRIES = 2;
const STALE_TIME = 60 * 1000; // 1 minute

const retryIfServerError = (failureCount, error) => error?.response?.status === 500 && failureCount < NUMBER_OF_RETRIES;

export const useMutateCollection = () => useMutation({
  mutationFn: collectionsService.updateCollection,
  retry: retryIfServerError
});

export const useFetchCollections = (params) => {
  const queryFn = async () => {
    try {
      useAppStore.getState().setPageLoading(true);
      const collections = await collectionsService.fetchCollections(params);
      return collections;
    }
    catch (err) {
      console.error("Error occurred while fetching collections", err);
      useAppStore.getState().setPageError(err.response.status);
      useAppStore.getState().setPageLoading(false);
      return null;
    }
  };

  const { isLoading, data } = useQuery({
    queryKey: [FETCH_QUERY_KEYS.COLLECTIONS, params.selectedAssistantId, params.collectionName],
    queryFn,
    enabled: !!params.selectedAssistantId && !!params.collectionName,
    staleTime: STALE_TIME
  });

  useEffect(() => {
    if (data) {
      useAppStore.getState().setCollections(data);
    }
  }, [data]);

  return { isLoading };
};

export const useFetchCollection = (params) => {
  const queryFn = async () => {
    try {
      useAppStore.getState().setPageLoading(true);
      useAppStore.getState().setCollectionLoading(true);
      const collection = await collectionsService.fetchCollection(params);
      return collection;
    }
    catch (err) {
      console.error("Error occurred while fetching collection", err);
      useAppStore.getState().setDialogError(true);
      useAppStore.getState().setPageLoading(false);
      useAppStore.getState().setCollectionLoading(false);
      return null;
    }
  };

  const { isLoading, data } = useQuery({
    queryKey: [FETCH_QUERY_KEYS.COLLECTION, params.selectedAssistantId, params.collectionName, params.collectionKey, params.collectionInstanceKey],
    queryFn,
    enabled: !!params.selectedAssistantId && !!params.collectionName && !!params.collectionKey && !!params.collectionInstanceKey,
    staleTime: STALE_TIME
  });

  useEffect(() => {
    if (data) {
      useAppStore.getState().setCollection(data);
    }
  }, [data]);

  return { isLoading };
};

export const useFetchCollectionRefs = (params) => {
  // Remove duplicates from refCollections
  const uniqueRefCollections = Array.from(new Map(params.refCollections.map((item) => [item.collection_id, item])).values());

  const queries = uniqueRefCollections.map(({ collection_id, query_params }) => ({
    queryKey: [FETCH_QUERY_KEYS.COLLECTION_REFS, collection_id],
    queryFn: async () => {
      const fetchParams = {
        selectedAssistantId: params.selectedAssistantId,
        collectionId: collection_id,
        payload: params.currentAssistantConfig,
        params: query_params
      };
      const collectionRefs = await collectionsService.fetchCollectionRefs(fetchParams);

      return collectionRefs.filter(({ display_key }) => display_key !== "");
    },
    enabled: !!params.selectedAssistantId && !!params.refCollections?.length,
    staleTime: STALE_TIME
  }));

  const queryResults = useQueries({ queries });
  const allOptions = params.refCollections.reduce((acc, { key, collection_id }) => {
    const index = uniqueRefCollections.findIndex((item) => item.collection_id === collection_id);
    acc[key] = queryResults[index]?.data || [];
    return acc;
  }, {});
  const isLoading = queryResults.some((result) => result.isLoading);
  const isError = queryResults.some((result) => result.isError);

  return { isLoading, data: allOptions, isError };
};

export default {
  useMutateCollection,
  useFetchCollections,
  useFetchCollection,
  useFetchCollectionRefs
};