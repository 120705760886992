import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { __, add, subtract, times } from "ramda";
import React, { useEffect } from "react";

const StyledDialog = styled(Dialog, {
  shouldForwardProp: prop => prop !== "dialogDims"
})(({ dialogDims }) => ({
  "& .MuiDialog-paper": dialogDims || {}
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  paddingRight: "48px",
  paddingLeft: "48px",
  paddingBottom: "48px"
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px 48px 48px 48px"
}));

const StyledButton = styled(Button)(() => ({
  color: "#00000099"
}));

const StepIndicator = ({ isActive }) => (
  <Box
    mx="4px"
    width="24px"
    height="4px"
    borderRadius="2px"
    bgcolor={isActive ? "#3F51B5" : "#E0E0E0"}
  />
);

const StepProgress = ({ numSteps, activeStep }) => (
  <Box display="flex" flexDirection="row" justifyContent="center" mt="24px">
    {times(
      stepNum => (
        <StepIndicator key={stepNum} isActive={activeStep >= stepNum} />
      ),
      numSteps
    )}
  </Box>
);

// eslint-disable-next-line max-lines-per-function, complexity
const HyroStepperDialog = (
  {
    open,
    steps,
    handleDone,
    handleCancel,
    handleBack,
    hideProgress,
    activeStep,
    setActiveStep,
    dontShowBackButton
  }
) => {
  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;
  const {
    autoNext,
    component,
    dialogDims,
    heading,
    nextDisabled,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonLoading = false,
    handleBack: stepHandleBack
  } = steps[activeStep];

  useEffect(() => {
    if (
      steps[activeStep].autoNext
            && !steps[activeStep].nextDisabled
            && !isLastStep
    ) {
      setActiveStep(add(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const handleClickNext = () => {
    if (!nextDisabled) {
      isLastStep ? handleDone() : setActiveStep(add(1));
    }
  };

  const onDialogKeyPress = (e) => {
    if (e.code === "Enter") {
      handleClickNext();
    }
  };

  return (
    <StyledDialog
      data-testid="hyro-stepper-dialog"
      onKeyPress={onDialogKeyPress}
      dialogDims={dialogDims}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      {!hideProgress
        ? (
          <StepProgress numSteps={steps.length} activeStep={activeStep} />
        )
        : (
          <Box mt={4} />
        )}
      <Typography variant='h5' textAlign='center' mt={"36px"}>
        {heading}
      </Typography>
      <StyledDialogContent>
        <Box
          height="240px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={1}
        >
          {component}
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        {(!isFirstStep && !dontShowBackButton) && (
          <Box flex={1}>
            <StyledButton
              disabled={isFirstStep}
              onClick={() => {
                if (typeof stepHandleBack === "function") stepHandleBack();
                else if (typeof handleBack === "function") handleBack();
                setActiveStep(subtract(__, 1));
              }}
              startIcon={(
                <ArrowBackIcon
                  sx={{
                    color: "#00000099"
                  }}
                />
              )}
            >
                            Back
            </StyledButton>
          </Box>
        )}
        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          columnGap="8px"
        >
          <Button variant={"text"}
            data-testid="cancel-button"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            onClick={handleCancel}>
            {secondaryButtonText ? secondaryButtonText : "Cancel"}
          </Button>
          {!autoNext && (
            <LoadingButton
              loading={primaryButtonLoading}
              variant="contained"
              color="primary"
              onClick={handleClickNext}
              disabled={nextDisabled}
              data-testid="hyro-dialog-next-button"
            >
              {primaryButtonText
                ? primaryButtonText
                : isLastStep
                  ? "Done"
                  : "Next"}
            </LoadingButton>
          )}
        </Box>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default HyroStepperDialog;
