import { Button } from "@hyro/ui";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { DIALOG_TYPES } from "./AccountEditorConsts";
import AccountEditorFields from "./AccountEditorFields";
import ConfirmationDialog from "./ConfirmationDialog";
import useAccountEditorDialog from "./useAccountEditorDialog";

// eslint-disable-next-line max-lines-per-function, complexity
const AccountEditorDialog = ({
  open,
  title,
  onClose,
  onConfirm,
  onResetError,
  accountData,
  type,
  error,
  errorText
}) => {
  const [newAccountData, setNewAccountData] = useState(
    accountData || {
      accountId: { value: "", errorText: "" },
      name: { value: "", errorText: "" }
    }
  );

  const { dialogContent } = useAccountEditorDialog({
    error,
    errorText,
    type,
    accountData: newAccountData
  });

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen]
    = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setIsConfirmationDialogOpen(false);
    }
  }, [error]);

  const isSaveDisabled
    = type !== "delete"
    && ((!!newAccountData?.name.errorText
        || newAccountData?.name.value === accountData?.name.value)
      && (!!newAccountData?.accountId.errorText
        || newAccountData?.accountId.value === accountData?.accountId.value));

  const handleTextFieldChange = ({ target }, validationFn) => {
    const errorText = validationFn(target.value);

    setNewAccountData({
      ...newAccountData,
      [target.name]: { value: target.value, errorText }
    });
  };

  // eslint-disable-next-line consistent-return
  const handleSaveButtonClicked = () => {
    if (error) return onResetError();

    if (type === DIALOG_TYPES.create || type === DIALOG_TYPES.delete) {
      typeof onConfirm === "function" && onConfirm(newAccountData);
      setIsLoading(true);
    }
    else {
      setIsConfirmationDialogOpen(true);
    }
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        title={title}
        onClose={onClose}
      >
        <DialogTitle>{dialogContent?.title}</DialogTitle>

        <DialogContent>
          <Typography
            style={{ whiteSpace: "pre-line" }}
            variant="body1"
            color="text.primary"
          >
            {dialogContent?.subtitle}
          </Typography>
          {type !== DIALOG_TYPES.delete && !error
            ? (
              <AccountEditorFields
                type={type}
                data={newAccountData}
                onChange={handleTextFieldChange}
              />
            )
            : null}
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button data-testid='cancel-create-account-button' onClick={onClose}>Cancel</Button>
          <LoadingButton
            data-testid='create-account-button'
            loading={!error && isLoading && !isConfirmationDialogOpen}
            onClick={handleSaveButtonClicked}
            variant="contained"
            disabled={!error && isSaveDisabled}
          >
            {dialogContent.actionButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {isConfirmationDialogOpen
        ? (
          <ConfirmationDialog
            open={isConfirmationDialogOpen}
            isSaveDisabled={isSaveDisabled}
            onClose={() => setIsConfirmationDialogOpen(false)}
            onDiscard={onClose}
            onSubmit={() => {
              typeof onConfirm === "function" && onConfirm(newAccountData);
              setIsLoading(true);
            }}
            isLoading={isLoading}
            accountName={accountData.name.value}
          />
        )
        : null}
    </>
  );
};

export default AccountEditorDialog;
