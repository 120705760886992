export const FIELDS = {
  SHOW: {
    TITLE: "Show notice banner",
    SUBTITLE: "Show a notice banner at the top of the page, in both edit and live modes.",
    NAME: "show"
  },
  BANNER_TYPE: {
    TITLE: "Banner type",
    NAME: "color"
  },
  OVERRIDE_BANNER_TEXT: {
    LABEL: "Override banner text",
    NAME: "text"
  },
  DISABLE_CHANGES: {
    TITLE: "Disable all changes",
    SUBTITLE: "Prevent any changes on assistant pages and disable the creation of assistants and accounts.",
    NAME: "disableChanges"
  },
  DISABLE_PUBLISH: {
    TITLE: "Disable publish",
    SUBTITLE: "Disable the ability to make changes live.",
    NAME: "disablePublish"
  },
  CUSTOM_MESSAGE: {
    LABEL: "Custom banner message",
    NAME: "customText"
  }
};

export const COLORS = {
  INCIDENT: "#EF404B",
  MAINTENANCE: "#F1B00A"
};

export const STATES = {
  ACTIVE: { disableChanges: true, disablePublish: true },
  INACTIVE: { disableChanges: false, disablePublish: false }
};

export const BANNER_TYPES = [
  {
    name: "Incident",
    color: COLORS.INCIDENT,
    text: "We're experiencing technical issues, editing is currently unavailable."
  },
  {
    name: "Maintenance",
    color: COLORS.MAINTENANCE,
    text: "System maintenance in progress, some features might be unavailable"
  }
];

export const LOCKED_STATUS_CODE = 423;
